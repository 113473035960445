import { Grid, Box, Card } from '@mui/material';
import { useSchools } from 'src/hooks/user/useSchools';
import SchoolList from 'src/components/schools/SchoolList';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ModalSchool } from 'src/components/schools/ModalSchool';
import PlusIcon from '../../icons/Plus';
import { schoolFields } from '../../models/school';

export const SchoolsContainer = () => {
  const {
    schools,
    params,
    handleOnPageChange,
    handleLimitChange,
    totalSchool,
    removeSchool,
    open,
    onOpenModal,
    idSchool,
    setIdSchool,
    onSubmit,
    handleSubmit,
    formItems,
    openModalSchool,
    onOpenModalSchool,
    setValue,
    watch,
    typeOfSchoolList
  } = useSchools();

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          onClick={() => {
            onOpenModalSchool(true);
            setIdSchool(null);
          }}
        >
          {t('actions.add', { name: schoolFields.school.label })}
        </Button>
      </Grid>
      <Card>
        <SchoolList
          schools={schools}
          setValue={setValue}
          totalSchool={totalSchool}
          removeSchool={removeSchool}
          idSchool={idSchool}
          setIdSchool={setIdSchool}
          open={open}
          onOpenModal={onOpenModal}
          handleOnPageChange={handleOnPageChange}
          handleLimitChange={handleLimitChange}
          openModalSchool={openModalSchool}
          onOpenModalSchool={onOpenModalSchool}
          {...params}
        />
      </Card>
      <ModalSchool
        typeOfSchoolList={typeOfSchoolList}
        watch={watch}
        onSubmit={onSubmit}
        idSchool={idSchool}
        handleSubmit={handleSubmit}
        {...formItems}
        openModalSchool={openModalSchool}
        onOpenModalSchool={onOpenModalSchool}
      />
    </Box>
  );
};
