import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordForm } from '../containers/forgotpassword';
// import Logo from '../components/Logo';

const ForgotPassword = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title> {t('auth.login.head')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Container maxWidth="sm" sx={{ py: '10px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 10,
              mt: 5
            }}
          >
            <RouterLink to="/">
              {/* <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              /> */}
            </RouterLink>
          </Box>
          <Card
            sx={{
              boxShadow: '0px 0px 10px 2px #0083f7'
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2
                }}
              >
                <Typography
                  color="#0083f7"
                  sx={{
                    my: 5,
                    fontWeight: 800
                  }}
                  gutterBottom
                  variant="h3"
                >
                  {t('auth.login.title')}
                </Typography>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mb: 5
                }}
              >
                <ForgotPasswordForm />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
