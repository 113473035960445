import { i18n } from '../i18n';
import StringField from './fields/stringField';
import IdField from './fields/idField';

const label = (field) => i18n.t(`form.fields.${field}`);
const placeholder = (field) => i18n.t(`form.placeholder.${field}`);

export const trainingSignalFields = {
  id: new IdField('id', 'id'),
  name: new StringField('name', label('name'), placeholder('name')),
  student: new StringField('student', label('student'), placeholder('student'), {
    required: true
  }),

  pdf: new StringField('pdf', label('pdf'), placeholder('pdf')),

  owner: new StringField('owner', label('owner'), placeholder('owner'), {
    required: true
  }),

  createdAt: new StringField('createdAt', label('createdAt'))
};

export default {
  trainingSignalFields,
  placeholder
};
