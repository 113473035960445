import { i18n } from '../i18n';
import StringField from './fields/stringField';
import IdField from './fields/idField';

const label = (field) => i18n.t(`form.fields.${field}`, { fields: 'State' });
const placeholder = (field) => i18n.t(`form.placeholder.${field}`);

export const stateFields = {
  id: new IdField('id', 'id'),
  name: new StringField('name', label('name'), placeholder('name'), {
    required: true
  }),
  code: new StringField('code', label('code'), placeholder('code'), {
    required: true
  }),
  country: new StringField('country', label('country'), placeholder('country'), {
    required: true
  }),
  state: new StringField('state', label('state')),
  action: new StringField('action', label('action')),
  createdAt: new StringField('createdAt', label('createdAt'))
};

export default {
  stateFields,
  placeholder
};
