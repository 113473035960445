import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputField from 'src/components/FormControls/InputField';
import { fields } from 'src/models/trainingReason';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

export const ModalTrainingReason = ({
  openModalTrainingReason,
  onOpenModalTrainingReason,
  onSubmit,
  handleSubmit,
  idTrainingReason,
  ...formItems
}) => {
  const { t } = useTranslation();

  return (
    <Dialog fullWidth maxWidth="md" open={openModalTrainingReason} onClose={() => onOpenModalTrainingReason(false)}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onOpenModalTrainingReason(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {idTrainingReason
              ? t('actions.update', { name: fields.trainingReason.label })
              : t('actions.add', { name: fields.trainingReason.label })}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.name.name} label={fields.name.label} required />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.code.name} label={fields.code.label} required />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOpenModalTrainingReason(false)}>
          {t('actions.cancel', { name: fields.trainingReason.label })}
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>
          {idTrainingReason
            ? t('actions.save', { name: fields.trainingReason.label })
            : t('actions.create', { name: fields.trainingReason.label })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ModalTrainingReason.propTypes = {
  idTrainingReason: PropTypes.string,
  openModalTrainingReason: PropTypes.bool,
  roles: PropTypes.array,
  watch: PropTypes.func,
  onOpenModalTrainingReason: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func
};
