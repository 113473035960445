import { Box, Card } from '@mui/material';
import { useConfigs } from 'src/hooks/useCustomConfigs';
import ConfigList from 'src/components/configs';

export const ConfigsContainer = () => {
  const { data, params } = useConfigs();

  // const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Card>
        <ConfigList data={data} {...params} />
      </Card>
    </Box>
  );
};
