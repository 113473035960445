import { Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { fields } from 'src/models/trainingReason';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useState } from 'react';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const TrainingReasonList = ({
  trainingReasons,
  page,
  pagesize,
  handleOnPageChange,
  handleLimitChange,
  totalTrainingReason,
  removeTrainingReason,
  open,
  onOpenModal,
  idTrainingReason,
  setIdTrainingReason,
  onOpenModalTrainingReason,
  setValue
}) => {
  const { t } = useTranslation();
  const [oderDirection, setOrderDirection] = useState('asc');
  const [valueToOderBy, setValueToOderBy] = useState(fields.name.label);

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOderBy === property && oderDirection === 'asc';
    setValueToOderBy(property);
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === fields.name.name}
                direction={valueToOderBy === fields.name.name ? oderDirection : 'asc'}
                onClick={createSortHandler(fields.name.name)}
              >
                {fields.name.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === fields.code.name}
                direction={valueToOderBy === fields.code.name ? oderDirection : 'asc'}
                onClick={createSortHandler(fields.code.name)}
              >
                {fields.code.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>{fields.action.label}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trainingReasons &&
            stableSort(trainingReasons, getComparator(oderDirection, valueToOderBy)).map((trainingReason) => (
              <TableRow key={trainingReason._id}>
                <TableCell>{trainingReason.name}</TableCell>
                <TableCell>{trainingReason.code}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      aria-label="edit"
                      onClick={() => {
                        onOpenModalTrainingReason(true);
                        setIdTrainingReason(trainingReason._id);
                        setValue('name', trainingReason.name);
                        setValue('code', trainingReason.code);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => {
                        onOpenModal(true);
                        setIdTrainingReason(trainingReason._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalTrainingReason}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={pagesize}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      <Dialog open={open} onClose={() => onOpenModal(false)}>
        <DialogTitle>{t('dialog.title')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => onOpenModal(false)}> {t('dialog.noButton')}</Button>
          <Button
            onClick={() => {
              removeTrainingReason(idTrainingReason);
              onOpenModal(false);
            }}
            autoFocus
          >
            {t('dialog.yesButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
TrainingReasonList.propTypes = {
  trainingReasons: PropTypes.array,
  page: PropTypes.number,
  pagesize: PropTypes.number,
  totalTrainingReason: PropTypes.number,
  open: PropTypes.bool,
  onOpenModal: PropTypes.func,
  onOpenModalTrainingReason: PropTypes.func,
  idTrainingReason: PropTypes.string,
  setIdTrainingReason: PropTypes.func,
  removeTrainingReason: PropTypes.func,
  handleOnPageChange: PropTypes.func,
  setValue: PropTypes.func,
  handleLimitChange: PropTypes.func
};
export default TrainingReasonList;
