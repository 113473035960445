import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import XlsxPopulate from 'xlsx-populate';
import { useNotify } from 'src/hooks/useNotify';
import PlusIcon from '../../icons/Plus';
import { programFields } from '../../models/program';

export const ImportProgram = ({ audioList, videoList, dingList, userList, onSubmitBatch }) => {
  //   const { t } = useTranslation();
  const { error } = useNotify();
  // region export template excel

  function getWorkbook() {
    return new Promise((resolve) => {
      const req = new XMLHttpRequest();
      req.open('GET', '/static/program_template.xlsx', true);
      req.responseType = 'arraybuffer';
      req.onreadystatechange = function a() {
        if (req.readyState === 4 && req.status === 200) {
          resolve(XlsxPopulate.fromDataAsync(req.response));
          // .then(function (workbook) {
          //     // ...
          // });
        }
      };

      req.send();
    });
  }
  // fetch(`/static/student_template.xlsx`).then((r) => XlsxPopulate.fromDataAsync(r));
  // return new Promise((resolve) => {
  //   fetch(`static/student_template.xlsx`).then((r) => resolve(XlsxPopulate.fromDataAsync(r)));
  //   // const req = new XMLHttpRequest();
  //   // const url = `static/student_template.xlsx`;
  //   // req.open('GET', url, true);
  //   // req.responseType = 'arraybuffer';
  //   // req.onreadystatechange = function (e) {
  //   //   if (req.readyState === 4) {
  //   //     if (req.status === 200) {
  //   //       resolve(XlsxPopulate.fromDataAsync(req.response));
  //   //     } else {
  //   //       reject(new Error(`Received a ${req.status} HTTP code. ${e}`));
  //   //     }
  //   //   }
  //   // };
  //   // req.send();
  // });
  // }

  function generate() {
    return (
      getWorkbook()
        // .then((r) => XlsxPopulate.fromDataAsync(r))
        .then((workbook) => {
          const sheet1 = workbook.sheet(1);
          //  console.log(countryListAll);
          // console.log(countryListAll.map((row) => row.label));
          // console.log(trainingReasonList);
          let celldata = audioList.map((row) => [row.label]);
          sheet1.range(`B1:B2`).value(celldata);
          celldata = videoList.filter((row) => row.value !== 0).map((row) => [row.label]);
          sheet1.range(`C1:C${celldata.length}`).value(celldata);
          console.log(dingList);
          celldata = dingList.filter((row) => row.value !== 0).map((row) => [row.label]);
          sheet1.range(`D1:D${celldata.length}`).value(celldata);

          return workbook.outputAsync();
        })
    );
  }

  async function exportUserTemplate() {
    return generate()
      .then((blob) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, 'program_template.xlsx');
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = url;
          a.download = 'program_template.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((err) => {
        error(err.message || err);
        throw err;
      });
  }

  function isNumber(n) {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }

  async function onFileChange(event) {
    // Update the state
    console.log('call onFileChange');
    const file = event.target.files[0];
    if (!file) return error('You must select a file.');
    return XlsxPopulate.fromDataAsync(file).then((workbook) => {
      // workbook.sheet(0).cell('A1').value('This was created in the browser!').style('fontColor', 'ff0000');
      //  const totalrows = workbook.sheet(0).cell(`M1`).value();

      // Get 2D array of all values in the worksheet.
      const values = workbook.sheet(0).usedRange().value();

      const lstData = [];

      for (let index = 1; index < values.length; index++) {
        // console.log(values[index]);
        const element = values[index].map((elem) => {
          if (typeof elem === 'string') {
            return elem.trim();
          }
          return elem;
        });

        if (element.every((e) => e === '' || e === undefined)) {
          // eslint-disable-next-line no-continue
          continue;
        }
        // console.log(element);
        const title = element[0];

        const video = videoList.find((elem) => element[3] === elem.label);
        const ding = dingList.find((elem) => element[4] === elem.label);
        const t1 = element[5];
        const t2 = element[6];
        const t3 = element[7];
        const t4 = element[8];
        const t5 = element[9];
        const t6 = element[10];
        const t7 = element[11];
        const t8 = element[12];
        const t9 = element[13];
        const t10 = element[14];
        let programDuration = element[15];
        if (!video) {
          error(`video in row ${index + 1} invalid`);
          return;
        }

        if (!ding) {
          error(`ding in row ${index + 1} invalid`);
          return;
        }

        if (programDuration) {
          if (!isNumber(programDuration)) {
            error(`total program duration in row ${index + 1} is not a number`);
            return;
          }
        } else {
          programDuration = -1;
        }
        if (title) {
          const studentname = userList.find((elem) => element[1] === elem.label);
          if (!studentname) {
            error(`studentname in row ${index + 1} not exists`);
            return;
          }
          const defaultValues = {
            [programFields.title.name]: title,
            [programFields.owner.name]: studentname.value,
            [programFields.playlist.name]: [],
            [programFields.flag.name]: 0,
            [programFields.audio.name]: null,
            [programFields.bgm.name]: false,
            [programFields.totalProgramDuration.name]: programDuration
          };
          // defaultValues[programFields.title.name] = title;
          // defaultValues[programFields.studentname.name] = studentname;
          if (element[2]) {
            const bgmaudio = audioList.find((elem) => element[2] === elem.label);
            if (!bgmaudio) {
              error(`bgm audio file in row ${index + 1} not exists`);
              return;
            }

            defaultValues[programFields.bgm.name] = true;
            defaultValues[programFields.audio.name] = bgmaudio.value;
          }
          lstData.push(defaultValues);
        } else if (index === 1) {
          error(`title in row ${index + 1} invalid`);
          return;
        }

        const defaultPlaylist = {
          [programFields.item.name]: {
            [programFields.video.name]: video.value,
            [programFields.ding.name]: ding.value,
            [programFields.timing.name]: [
              { setup: '1', [programFields.seconds.name]: t1 },
              { setup: '2', [programFields.seconds.name]: t2 },
              { setup: '3', [programFields.seconds.name]: t3 },
              { setup: '4', [programFields.seconds.name]: t4 },
              { setup: '5', [programFields.seconds.name]: t5 },
              { setup: '6', [programFields.seconds.name]: t6 },
              { setup: '7', [programFields.seconds.name]: t7 },
              { setup: '8', [programFields.seconds.name]: t8 },
              { setup: '9', [programFields.seconds.name]: t9 },
              { setup: '10', [programFields.seconds.name]: t10 }
            ]
          }
        };

        lstData[lstData.length - 1][programFields.playlist.name].push(defaultPlaylist);
      }

      console.log(lstData);

      if (lstData.length > 0) {
        onSubmitBatch(lstData);
      } else {
        error(`Nothing to import`);
      }
    });
  }
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<PlusIcon fontSize="small" />}
        onClick={() => {
          exportUserTemplate();
        }}
      >
        Download template
      </Button>
      <label htmlFor="contained-button-file">
        <input id="contained-button-file" type="file" onChange={onFileChange} style={{ display: 'none' }} />
        <Button variant="contained" component="span">
          Upload
        </Button>
      </label>
    </>
  );
};
ImportProgram.propTypes = {
  audioList: PropTypes.array,
  videoList: PropTypes.array,
  dingList: PropTypes.array,
  userList: PropTypes.array,
  onSubmitBatch: PropTypes.func
};
