import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputField from 'src/components/FormControls/InputField';
import { fields } from 'src/models/user';
import DesktopDateField from 'src/components/FormControls/DesktopDateField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RadioField from 'src/components/FormControls/RadioField';
// import { useCountries } from 'src/hooks/user/useCountries';
// import { useStates } from 'src/hooks/user/useStates';
// import { useTrainingReasons } from 'src/hooks/user/useTrainingReasons';
// import { useSchools } from 'src/hooks/user/useSchools';
// import { useEffect } from 'react';
import PasswordField from '../FormControls/PasswordField';

export const ModalUserAdmin = ({
  openModalUserAdmin,
  onOpenModalUserAdmin,
  onSubmitAdmin,
  handleSubmit,
  idUser,
  setUserName,
  ...formItems
}) => {
  const { t } = useTranslation();
  // const values = watch();

  // const { trainingReasonList } = useTrainingReasons(values.trainingReason);

  // const valueStudentID = { ...formItems.username };

  // useEffect(() => {
  //   if (valueStudentID) {
  //     setUserName(valueStudentID);
  //   }
  // }, [valueStudentID]);

  return (
    <Dialog fullWidth maxWidth="md" open={openModalUserAdmin} onClose={() => onOpenModalUserAdmin(false)}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onOpenModalUserAdmin(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Update User Admin
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.username.name} label={fields.username.label} disabled />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.fullName.name} label={fields.fullName.label} />
          </Grid>
          <Grid item xs={6}>
            <RadioField
              required
              autoFocus
              row
              label={fields.gender.label}
              name={fields.gender.name}
              options={fields.gender.options}
              {...formItems}
            />
          </Grid>
          <Grid item xs={6}>
            <DesktopDateField {...formItems} name={fields.joinDate.name} label={fields.joinDate.label} />
          </Grid>
          <Grid item xs={6}>
            <DesktopDateField {...formItems} name={fields.birthday.name} label={fields.birthday.label} />
          </Grid>
          <Grid item xs={6}>
            <PasswordField
              {...formItems}
              required={!idUser}
              autoComplete="current-password"
              name={idUser ? fields.resetPassword.name : fields.password.name}
              label={idUser ? fields.resetPassword.label : fields.password.label}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.remarks.name} label={fields.remarks.label} />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.note.name} label={fields.note.label} />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.phone.name} label={fields.phone.label} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOpenModalUserAdmin(false)}>{t('users.action.cancel')}</Button>
        <Button onClick={handleSubmit(onSubmitAdmin)}>{t('users.action.save')}</Button>
      </DialogActions>
    </Dialog>
  );
};
ModalUserAdmin.propTypes = {
  idUser: PropTypes.string,
  openModalUserAdmin: PropTypes.bool,
  onOpenModalUserAdmin: PropTypes.func,
  setUserName: PropTypes.func,
  onSubmitAdmin: PropTypes.func,
  handleSubmit: PropTypes.func
};
