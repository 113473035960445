import { Grid, Box, Card, FormControl, Select, MenuItem, InputLabel, Checkbox } from '@mui/material';
import { useProgramLogs } from 'src/hooks/programs/useProgramLogs';
import { useSchools } from 'src/hooks/user/useSchools';
import ProgramList from 'src/components/programlogs/ProgramList';
// import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

export const ProgramLogsContainer = () => {
  const {
    programs,
    params,
    handleOnPageChange,
    handleLimitChange,
    totalPrograms,
    removePrograms,
    open,
    onOpenModal,
    idProgram,
    setIdProgram,
    setValue,
    handleSearchChange,
    handleSchoolChange,
    handleBeginStartDay,
    handleEndStartDay,
    handleStartDateFiltering
  } = useProgramLogs();

  const { schoolListAll } = useSchools();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-search"
            onChange={handleSearchChange}
            value={params.studentname}
            variant="outlined"
            fullWidth
            label="Search student name"
            type="search"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="school-select-label">School</InputLabel>
            <Select
              labelId="school-select-labell"
              id="school-select"
              value={params.schoolid}
              label="School"
              onChange={handleSchoolChange}
            >
              {schoolListAll &&
                schoolListAll.map((item) => (
                  <MenuItem value={item.value} key={`school.${item.value}`}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction="row" spacing={2}>
                <Checkbox checked={params.enableStartDateFiltering} onChange={handleStartDateFiltering} />
                <DatePicker
                  disabled={!params.enableStartDateFiltering}
                  label="Begin start date"
                  inputFormat="dd/MM/yyyy"
                  value={params.startbegindate}
                  onChange={handleBeginStartDay}
                  renderInput={(params1) => <TextField {...params1} />}
                />
                <DatePicker
                  disabled={!params.enableStartDateFiltering}
                  label="End start date"
                  inputFormat="dd/MM/yyyy"
                  value={params.endbegindate}
                  onChange={handleEndStartDay}
                  renderInput={(params2) => <TextField {...params2} />}
                />
              </Stack>
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Card>
        <ProgramList
          programs={programs}
          setValue={setValue}
          totalPrograms={totalPrograms}
          removePrograms={removePrograms}
          idProgram={idProgram}
          setIdProgram={setIdProgram}
          open={open}
          onOpenModal={onOpenModal}
          handleOnPageChange={handleOnPageChange}
          handleLimitChange={handleLimitChange}
          {...params}
        />
      </Card>
    </Box>
  );
};
