import { Grid, Box, Card } from '@mui/material';
import { useTrainingReasons } from 'src/hooks/user/useTrainingReasons';
import TrainingReasonList from 'src/components/trainingReasons/TrainingReasonList';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ModalTrainingReason } from 'src/components/trainingReasons/ModalTrainingReason';
import PlusIcon from '../../icons/Plus';
import { fields } from '../../models/trainingReason';

export const TrainingReasonsContainer = () => {
  const {
    trainingReasons,
    params,
    handleOnPageChange,
    handleLimitChange,
    totalTrainingReason,
    removeTrainingReason,
    open,
    onOpenModal,
    idTrainingReason,
    setIdTrainingReason,
    onSubmit,
    handleSubmit,
    formItems,
    openModalTrainingReason,
    onOpenModalTrainingReason,
    setValue
  } = useTrainingReasons();

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          onClick={() => {
            onOpenModalTrainingReason(true);
            setIdTrainingReason(null);
          }}
        >
          {t('actions.add', { name: fields.trainingReason.label })}
        </Button>
      </Grid>
      <Card>
        <TrainingReasonList
          trainingReasons={trainingReasons}
          setValue={setValue}
          totalTrainingReason={totalTrainingReason}
          removeTrainingReason={removeTrainingReason}
          idTrainingReason={idTrainingReason}
          setIdTrainingReason={setIdTrainingReason}
          open={open}
          onOpenModal={onOpenModal}
          handleOnPageChange={handleOnPageChange}
          handleLimitChange={handleLimitChange}
          openModalTrainingReason={openModalTrainingReason}
          onOpenModalTrainingReason={onOpenModalTrainingReason}
          {...params}
        />
      </Card>
      <ModalTrainingReason
        onSubmit={onSubmit}
        idTrainingReason={idTrainingReason}
        handleSubmit={handleSubmit}
        {...formItems}
        openModalTrainingReason={openModalTrainingReason}
        onOpenModalTrainingReason={onOpenModalTrainingReason}
      />
    </Box>
  );
};
