export default {
  locale: 'en-US',
  form: {
    fields: {
      username: 'User name',
      phoneNumber: 'Phone number',
      password: 'Password',
      resetPassword: 'Reset password',
      firstName: 'First name',
      lastName: 'Last name',
      gender: 'Gender',
      email: 'Email',
      address: 'Address',
      account: 'Account',
      dob: 'Date of birth',
      male: 'Male',
      female: 'Female',
      oldPassword: 'Old password',
      passwordConfirmation: 'Password confirmation',
      fullName: 'Full name',
      clientId: 'Client ID',
      studentId: 'Student ID',
      trainingId: 'Training ID',
      joinDate: 'Joining Date',
      status: 'Status',
      action: 'Action',
      idNumber: 'Id number',
      name: 'Name',
      inActive: 'Inactive',
      active: 'Active',
      holding: 'Holding',
      birthday: 'Birthday',
      trainingReason: 'Training Reason',
      trainingReasonCode: 'Training Reason Code',
      trainingReasonName: 'Training Reason',
      countryCode: 'Country Code',
      countryName: 'Country',
      country: 'Country',
      state: 'State',
      school: 'School',
      stateCode: 'State Code',
      stateName: 'State',
      schoolCode: 'School Code',
      schoolName: 'School',
      schoolCategory: 'School Category',
      remarks: 'Remarks',
      note: 'Notes',
      role: 'Role',
      student: 'Student',
      videos: 'Videos',
      startProgram: 'Start Program',
      endProgram: 'End Program',
      owner: 'Student',
      title: 'Title',
      video: 'Video',
      ding: 'Ding',
      code: 'Code',
      category: 'Category',
      typeOfSchool: 'Type Of School',
      token: 'Token',
      phone: 'Phone',
      bgm: 'BGM',
      audio: 'Audio',
      repeat: 'Repeat',
      totalProgramDuration: 'Total Program Duration (s)',
      expireDate: 'Expire Date',
      activationDate: 'Activation Date',
      holdingNote: 'Holding note',
      createdAt: 'Create time'
    },
    placeholder: {
      username: 'Input your user name',
      phoneNumber: 'Input your phone number',
      password: 'Input your password',
      firstName: 'Input your first name',
      lastName: 'Input your last name',
      email: 'Input your email',
      passwordConfirmation: 'Password confirmation'
    }
  },
  auth: {
    login: {
      head: 'Login | BRAINSC',
      haveAccount: 'Do not have an account ?',
      register: 'Register',
      title: 'BRAINSC',
      subtitle: 'Login to use',
      submit: 'Login',
      success: 'Login successful',
      error: 'Login failed',
      forgotpassword: 'Get Password',
      invalidUserAndEmail: 'User and email not exists',
      forgotSuccess: 'Email send',
      resetpassword: 'Reset Password',
      resetPasswordSuccess: 'Reset password done. Please check your email',
      invalidToken: 'Invalid token'
    }
  },
  sidebar: {
    overview: 'Overview',
    account: 'Account',
    settings: 'Setting',
    management: 'Management',
    users: 'Users',
    programs: 'Programs',
    programlogs: 'Program user',
    videos: 'Videos',
    trainingsignal: 'Neural signals',
    config: 'Config'
  },
  users: {
    logOut: 'Log out',
    users: 'Users',
    helmet: 'Users | BRAINSC',
    status: {
      0: 'Inactive',
      1: 'Active',
      2: 'Holding'
    },
    action: {
      removeSuccess: 'Remove user successful !',
      removeFailed: 'Remove user failed !',
      updateSuccess: 'Update user successful !',
      updateFailed: 'Update user failed !',
      createSuccess: 'Create user successful !',
      createFailed: 'Create user failed !',
      addUser: 'Add user',
      updateUser: 'Update user',
      cancel: 'Cancel',
      create: 'Create user',
      save: 'Save user',
      export: 'Export'
    },
    detail: { helmet: 'User Detail | BRAINSC', userDetail: 'User Detail', title: 'Personal information' },
    tabs: { users: 'Users', country: 'Country', state: 'State', school: 'School', trainingReason: 'Training Reason' }
  },
  actions: {
    removeSuccess: 'Remove {{name}} successful !',
    removeFailed: 'Remove {{name}} failed !',
    updateSuccess: 'Update {{name}} successful !',
    updateFailed: 'Update {{name}} failed !',
    createSuccess: 'Create {{name}} successful !',
    createFailed: 'Create {{name}} failed !',
    add: 'Add {{name}}',
    update: 'Update {{name}}',
    cancel: 'Cancel',
    create: 'Create {{name}}',
    save: 'Save {{name}}'
  },
  videos: {
    helmet: 'Videos | BRAINSC',
    videos: 'Videos',
    status: { true: 'Active', false: 'Inactive' },
    action: { addVideo: 'Add video', cancel: 'Close', create: 'Create video', save: 'Save video' }
  },
  programs: {
    helmet: 'Programs | BRAINSC',
    programs: 'Programs',
    status: {
      0: 'Not started',
      1: 'Doing',
      2: 'Done'
    },
    action: {
      removeSuccess: 'Remove program successful !',
      removeFailed: 'Remove program failed !',
      updateSuccess: 'Update program successful !',
      updateFailed: 'Update program failed !',
      createSuccess: 'Create program successful !',
      createFailed: 'Create program failed !',
      addProgram: 'Add program',
      updateProgram: 'Update program',
      cancel: 'Cancel',
      create: 'Create program',
      save: 'Save program'
    }
  },
  programslog: {
    helmet: 'Program student',
    programs: 'Programs',
    status: {
      0: 'Not started',
      1: 'Doing',
      2: 'Done'
    },
    action: {
      removeSuccess: 'Remove program successful !',
      removeFailed: 'Remove program failed !',
      updateSuccess: 'Update program successful !',
      updateFailed: 'Update program failed !',
      createSuccess: 'Create program successful !',
      createFailed: 'Create program failed !',
      addProgram: 'Add program',
      updateProgram: 'Update program',
      cancel: 'Cancel',
      create: 'Create program',
      save: 'Save program'
    }
  },
  dialog: { title: 'Are you sure to delete ?', yesButton: 'Yes', noButton: 'No' },
  moment: {
    relativeTime: {
      future: 'in %s',
      past: 'ago %s',
      s: 'few seconds ago',
      ss: '%d seconds',
      m: 'a minute',
      mm: '%d minutes',
      h: 'an hour',
      hh: '%d hours',
      d: 'a day',
      dd: '%d day',
      w: 'a week',
      ww: '%d weeks',
      M: 'a month',
      MM: '%d months',
      y: 'a year',
      yy: '%d years'
    }
  },
  common: {
    messages: {
      error: 'Error'
    },
    actions: {
      no: 'No',
      yes: 'Yes',
      save: 'Save',
      next: 'Next',
      back: 'Back',
      delete: 'Delete',
      submit: 'Submit',
      ingore: 'Ingore',
      create: 'Create',
      import: 'Import',
      export: 'Export',
      confirm: 'Confirm',
      search: 'Search',
      cancel: 'Cancel',
      reset: 'Reset',
      edit: 'Edit',
      update: 'Update',
      dismiss: 'Dismiss',
      restore: 'Restore',
      template: 'Download Template',
      restoreSelected: 'Restore All',
      deleteSelected: 'Delete All'
    },
    state: {
      on: 'On',
      off: 'Off',
      none: 'None',
      undefined: ''
    },
    modal: {
      preview: 'Preview'
    },
    days: {
      morning: 'morning',
      evening: 'evening',
      afternoon: 'afternoon'
    },
    labels: {
      actions: 'Actions'
    },
    status: {
      active: 'Active',
      deactive: 'Deactive',
      activated: 'Activated',
      deactivated: 'Deactivated'
    },
    list: {
      deactivate: {
        success: 'Delete {{item}} successfully!',
        failed: 'Delete {{item}} failed!',
        failure: 'Delete {{item}} failed!',
        confirm: 'Are you sure you want to delete {{item}} list is selected?'
      },
      result: 'Result {{total}} of {{records}} records'
    }
  },
  // See https://github.com/jquense/yup#using-a-custom-locale-dictionary
  /* eslint-disable */
  validation: {
    mixed: {
      default: '${path} is invalid',
      required: '${path} is required',
      oneOf: '${path} must be one of the following values: ${values}',
      notOneOf: '${path} must not be one of the following values: ${values}',
      notType: ({ path, type, value, originalValue }) => {
        return `${path} must be a ${type}`;
      }
    },
    string: {
      length: '${path} must be exactly ${length} characters',
      min: '${path} must be at least ${min} characters',
      max: '${path} must be at most ${max} characters',
      matches: '${path} must match the following: "${regex}"',
      email: '${path} must be a valid email',
      url: '${path} must be a valid URL',
      trim: '${path} must be a trimmed string',
      lowercase: '${path} must be a lowercase string',
      uppercase: '${path} must be a upper case string',
      selected: '${path} must be selected',
      required: '${path} bla'
    },
    number: {
      min: '${path} must be greater than or equal to ${min}',
      max: '${path} must be less than or equal to ${max}',
      lessThan: '${path} must be less than ${less}',
      moreThan: '${path} must be greater than ${more}',
      notEqual: '${path} must be not equal to ${notEqual}',
      positive: '${path} must be a positive number',
      negative: '${path} must be a negative number',
      integer: '${path} must be an integer'
    },
    date: {
      min: '${path} field must be later than ${min}',
      max: '${path} field must be at earlier than ${max}'
    },
    boolean: {},
    object: {
      noUnknown: '${path} field cannot have keys not specified in the object shape'
    },
    array: {
      min: '${path} field must have at least ${min} items',
      max: '${path} field must have less than or equal to ${max} items'
    }
  },
  /* eslint-disable */
  fileUploader: {
    upload: 'Upload',
    image: 'You must upload an image',
    size: 'File is too big. Max allowed size is {0}',
    formats: `Invalid format. Must be '{0}'.`
  },
  importer: {
    line: 'Line',
    status: 'Status',
    pending: 'Pending',
    imported: 'Imported',
    error: 'Error',
    total: `{0} imported, {1} pending and {2} with error`,
    importedMessage: `Processed {0} of {1}.`,
    noNavigateAwayMessage: 'Do not navigate away from this page or import will be stopped.',
    completed: {
      success: 'Import completed. All rows were successfully imported.',
      someErrors: 'Processing completed, but some rows were unable to be imported.',
      allErrors: 'Import failed. There are no valid rows.'
    },
    form: {
      downloadTemplate: 'Download the template',
      hint: 'Click or drag the file to this area to continue'
    },
    list: {
      discardConfirm: 'Are you sure? Non-imported data will be lost.'
    },
    errors: {
      invalidFileEmpty: 'The file is empty',
      invalidFileExcel: 'Only excel (.xlsx) files are allowed',
      invalidFileUpload: 'Invalid file. Make sure you are using the last version of the template.',
      importHashRequired: 'Import hash is required',
      importHashExistent: 'Data has already been imported'
    }
  }
};
