import { Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { programFields } from 'src/models/program';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import Link from '@mui/material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import moment from 'moment';

const ProgramList = ({
  programs,
  page,
  pagesize,
  handleOnPageChange,
  handleLimitChange,
  totalPrograms,
  removePrograms,
  open,
  onOpenModal,
  idProgram
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{programFields.name.label}</TableCell>
            <TableCell>{programFields.student.label}</TableCell>
            <TableCell>School</TableCell>
            <TableCell>{programFields.videos.label}</TableCell>
            <TableCell>{programFields.startProgram.label}</TableCell>
            <TableCell>{programFields.endProgram.label}</TableCell>
            {/* <TableCell>{programFields.repeat.label}</TableCell> */}
            <TableCell>{programFields.status.label}</TableCell>
            {/* <TableCell>PDF Result</TableCell> */}
            {/* <TableCell>{programFields.action.label}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {programs &&
            programs.map((program) => (
              <TableRow key={program._id}>
                <TableCell>{program.title}</TableCell>
                <TableCell>{program.users?.fullName}</TableCell>
                <TableCell>{program.schools?.name}</TableCell>
                <TableCell>{program.playlist.length}</TableCell>
                <TableCell> {program.start && moment(program.start).format('DD/MM/YYYY hh:mm A')}</TableCell>
                <TableCell> {program.end && moment(program.end).format('DD/MM/YYYY hh:mm A')}</TableCell>
                {/* <TableCell> {program.repeat}</TableCell> */}
                <TableCell>{program.title && t(`programs.status.${program.flag}`)}</TableCell>
                {/* <TableCell> {program.pdf && <Link href={program.pdf}>Link</Link>}</TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalPrograms}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={pagesize}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      <Dialog
        open={open}
        onClose={() => onOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('dialog.title')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => onOpenModal(false)}> {t('dialog.noButton')}</Button>
          <Button
            onClick={() => {
              removePrograms(idProgram);
              onOpenModal(false);
            }}
            autoFocus
          >
            {t('dialog.yesButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
ProgramList.propTypes = {
  programs: PropTypes.array,
  page: PropTypes.number,
  pagesize: PropTypes.number,
  totalPrograms: PropTypes.number,
  open: PropTypes.bool,
  onOpenModal: PropTypes.func,
  idProgram: PropTypes.string,
  removePrograms: PropTypes.func,
  handleOnPageChange: PropTypes.func,
  handleLimitChange: PropTypes.func
};
export default ProgramList;
