import { useParams } from 'react-router';
import { Box, Grid } from '@mui/material';
import { LayoutContainer, LayoutHeader } from 'src/components/layout';
import { useTranslation } from 'react-i18next';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { UserDetail } from '../../components/users/UserDetail';
import { useUserDetail } from '../../hooks/user/useUserDetail';
import { DashboardSlider, SliderItem } from '../../components/dashboard/DashboardSlider';

const UserDetailContainer = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { user, loading, formItems, programs } = useUserDetail(id);

  return (
    <LayoutContainer
      title={t('users.detail.userDetail')}
      header={
        <LayoutHeader
          title={t('users.detail.userDetail')}
          breadcrumbs={[
            {
              path: '/admin/user',
              name: `${t('users.users')}`
            },
            {
              path: '#',
              name: `${t('users.detail.userDetail')}`
            }
          ]}
        />
      }
    >
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%'
        }}
      >
        <UserDetail user={user} loading={loading} {...formItems} />
        <Grid container>
          {programs &&
            programs.map((program) => (
              <Grid item xs={12} key={program._id}>
                <Box sx={{ flexDirection: 'row', display: 'flex' }}>
                  <Typography variant="h4">{program.title} </Typography>
                </Box>

                <DashboardSlider>
                  {program.playlist.map((item) => (
                    <SliderItem key={item._id}>
                      <CardMedia
                        component="img"
                        image={
                          `${process.env.REACT_APP_BASE_URL}/public/img/${item.item.video.thumbnail}` ||
                          '/static/images/placeholder.png'
                        }
                      />
                    </SliderItem>
                  ))}
                </DashboardSlider>
              </Grid>
            ))}
        </Grid>
      </Box>
    </LayoutContainer>
  );
};
export default UserDetailContainer;
