/* eslint-disable react/no-array-index-key */
import { useFieldArray } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import NumberField from 'src/components/FormControls/NumberField';

export const TimingField = ({ index, ...formItems }) => {
  const { fields } = useFieldArray({
    control: formItems.control,
    name: `playlist[${index}].item.timing`
  });

  return (
    <Grid container columnSpacing={2}>
      {fields.map((field, indexTiming) => (
        <Grid item xs={1.2} key={indexTiming}>
          <NumberField
            {...formItems}
            name={`playlist[${index}].item.timing.[${indexTiming}].seconds`}
            label={`t${indexTiming + 1}`}
            variant="outlined"
          />
        </Grid>
      ))}
    </Grid>
  );
};
TimingField.propTypes = {
  control: PropTypes.any,
  index: PropTypes.number
};
