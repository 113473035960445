import axios from 'src/utils/axios';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNotify } from '../useNotify';
import { FormSchema } from '../../models/schema';
import { videoFields } from '../../models/video';

const initialParams = {
  pagesize: 10,
  page: 0
};

export const useVideos = () => {
  const [videos, setVideos] = useState([]);
  const [idVideo, setIdVideo] = useState('');
  const [error, setError] = useState();
  const [params, setParams] = useState(initialParams);

  const [open, setOpen] = useState(false);
  const [openModalVideo, setOpenModalVideo] = useState(false);

  const { t } = useTranslation();
  const { success, error: errorMsg } = useNotify();

  const { schema } = useMemo(() => new FormSchema(videoFields.id, [videoFields.title]), []);

  const { handleSubmit, control, formState, watch, reset, setValue } = useForm({
    defaultValues: {
      [videoFields.title.name]: ''
    },
    resolver: schema
  });

  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  //   const onSubmit = useCallback(async (formData) => {
  //     try {
  //       if (idVideo) {
  //         await axios.patch(`admin/video/${idVideo}`, formData);
  //         success(t('actions.updateSuccess', { title: videoFields.title.label }));
  //       } else {
  //         console.log(formData);
  //         await axios.post('admin/video', formData);
  //         success(t('actions.createSuccess', { title: videoFields.title.label }));
  //       }
  //       reset();
  //       // fetchCountries();
  //       setOpenModalVideo(false);
  //     } catch (e) {
  //       console.log(e);
  //       if (idVideo) {
  //         errorMsg(t('actions.updateFailed', { name: videoFields.title.label }));
  //       } else {
  //         errorMsg(t('actions.createFailed', { name: videoFields.title.label }));
  //       }
  //     }
  //   });

  const onSubmit = async (file) => {
    const formData = new FormData();

    formData.append('user-file', file);

    const { data } = await axios.post('admin/video-upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    if (data?.isSuccess) {
      success('upload complete');
    } else {
      setError({ success: data?.isSuccess, error: data?.msg });
    }
  };
  const fetchVideos = async () => {
    try {
      const { data } = await axios.get(`/admin/video`);
      setVideos(data?.data);
      setError({ success: data?.isSuccess, error: data?.msg });
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const removeCountry = async (id) => {
    try {
      await axios.delete(`/admin/country/${id}`);
      success(t('actions.removeSuccess', { name: videoFields.title.label }));
      fetchVideos();
    } catch (e) {
      errorMsg(t('actions.removeFailed', { name: videoFields.title.label }));
      console.log(e);
    }
  };

  const handleLimitChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      pagesize: parseInt(event.target.value, 10),
      page: 0
    }));
  }, []);

  const handleOnPageChange = (event, newPage) => {
    setParams((pre) => ({
      ...pre,
      page: newPage
    }));
  };

  const onOpenModal = useCallback((isOpen) => {
    setOpen(isOpen);
  }, []);

  const onOpenModalVideo = useCallback((isOpen) => {
    setOpenModalVideo(isOpen);
    reset();
  }, []);

  useEffect(() => {
    fetchVideos();
  }, []);

  return {
    videos,
    setParams,
    params,
    error,
    handleOnPageChange,
    handleLimitChange,
    removeCountry,
    open,
    onOpenModal,
    onOpenModalVideo,
    openModalVideo,
    setValue,
    setIdVideo,
    idVideo,
    handleSubmit,
    isSubmitting,
    formItems,
    control,
    watch,
    onSubmit
  };
};
