import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';
import { ProgramLogsContainer } from 'src/containers/programs/logs';
import { Helmet } from 'react-helmet';
import useSettings from '../../hooks/useSettings';

const ProgramLogs = () => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  return (
    <>
      <Helmet>
        <title>{t('programlogs.helmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4
        }}
      >
        <Container maxWidth={settings.compact ? 'xxl' : false}>
          <Box sx={{ mt: 3 }}>
            <ProgramLogsContainer />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ProgramLogs;
