import { Navigate } from 'react-router';
import DashboardLayout from 'src/components/dashboard/DashboardLayout';
import MainLayout from './components/MainLayout';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Settings from './pages/settings';
import UserDetailContainer from './containers/users/Detail';
import Programs from './pages/programs';
import TrainingSignals from './pages/TrainingSignals';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Videos from './pages/videos';
import Configs from './pages/Configs';
import ProgramLogs from './pages/programs/logs';

const userRoutes = [
  {
    path: 'admin',
    element: <DashboardLayout />,
    children: [
      { path: 'user', element: <Dashboard /> },
      { path: 'user/:id', element: <UserDetailContainer /> },
      { path: 'program', element: <Programs /> },
      { path: 'programlogs', element: <ProgramLogs /> },
      { path: 'settings', element: <Settings /> },
      { path: 'videos', element: <Videos /> },
      { path: 'trainingsignals', element: <TrainingSignals /> },
      { path: 'configs', element: <Configs /> }
    ]
  }
];
const publicRoutes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgotpassword', element: <ForgotPassword /> },
      { path: 'resetpassword/:token/:email', element: <ResetPassword /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/admin/user" /> },
      { path: '*', element: <Navigate to="/login" /> }
    ]
  }
];
export default () => [...userRoutes, ...publicRoutes];
