import axios from 'src/utils/axios';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useNotify } from '../useNotify';
import { FormSchema } from '../../models/schema';
import { fields } from '../../models/user';

const initialParams = {
  pagesize: 10,
  page: 0,
  studentname: '',
  countryid: '',
  stateid: '',
  schoolid: ''
};

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [idUser, setIdUser] = useState('');
  const [error, setError] = useState();
  const [params, setParams] = useState(initialParams);
  const [roles, setRoles] = useState([]);
  const [errorRoles, setErrorRoles] = useState();
  const [userName, setUserName] = useState('');
  const [open, setOpen] = useState(false);
  const [openModalUser, setOpenModalUser] = useState(false);

  const { t } = useTranslation();
  const { success, error: errorMsg } = useNotify();

  const { schema } = useMemo(
    () =>
      new FormSchema(fields.id, [
        fields.username,
        fields.password,
        fields.resetPassword,
        fields.trainingId,
        fields.role,
        fields.fullName,
        fields.gender,
        fields.birthday,
        fields.joinDate,
        fields.country,
        fields.state,
        fields.school,
        fields.trainingReason,
        fields.remarks,
        fields.note,
        fields.status,
        fields.expireDate,
        fields.activationDate,
        fields.holdingNote
      ]),
    []
  );

  const { handleSubmit, control, formState, watch, reset, setValue } = useForm({
    defaultValues: {
      [fields.username.name]: userName || '',
      [fields.resetPassword.name]: '',
      [fields.password.name]: '',
      [fields.trainingId.name]: '',
      [fields.role.name]: '',
      [fields.fullName.name]: '',
      [fields.gender.name]: '',
      [fields.birthday.name]: moment.utc(new Date()),
      [fields.joinDate.name]: moment.utc(new Date()),
      [fields.country.name]: '',
      [fields.state.name]: '',
      [fields.school.name]: '',
      [fields.trainingReason.name]: '',
      [fields.remarks.name]: '',
      [fields.note.name]: '',
      [fields.status.name]: 0,
      [fields.holdingNote.name]: '',
      [fields.expireDate.name]: moment.utc(new Date(new Date().setFullYear(new Date().getFullYear() + 1))),
      [fields.activationDate.name]: moment.utc(new Date())
    },
    resolver: schema
  });

  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const onSubmit = useCallback(async (formData) => {
    try {
      const formatData = {
        username: userName,
        role: formData.role,
        fullName: formData.fullName,
        gender: formData.gender,
        joinDate: formData.joinDate,
        birthday: formData.birthday,
        trainingId: formData.trainingId,
        password: formData.resetPassword !== '' ? formData.resetPassword : formData.password,
        trainingReason: formData.trainingReason,
        country: formData.country,
        state: formData.state,
        school: formData.school,
        remarks: formData.remarks,
        note: formData.note,
        status: parseInt(formData.status, 10),
        expireDate: formData.expireDate,
        activationDate: formData.activationDate,
        holdingNote: formData.holdingNote
      };
      if (idUser) {
        await axios.patch(`admin/user/${idUser}`, formatData);
        // console.log(formatData);
        success(t('users.action.updateSuccess'));
      } else {
        await axios.post('admin/user/register', formatData);
        success(t('users.action.createSuccess'));
      }
      reset();
      fetchUsers();
      setOpenModalUser(false);
    } catch (e) {
      console.log(e);
      if (idUser) {
        errorMsg(t('users.action.updateFailed'));
      } else {
        errorMsg(t('users.action.createFailed'));
      }
    }
  });

  const onSubmitBatch = useCallback(async (batchdata) => {
    try {
      const { data } = await axios.post('admin/user/registerbatch', batchdata);
      console.log(data);
      if (data.exitcode) {
        success(t('users.action.createSuccess'));
        reset();
        fetchUsers();
      } else {
        errorMsg(data?.msg);
      }
    } catch (e) {
      console.log(e);
      errorMsg(t('users.action.createFailed'));
    }
  });

  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(
        `/admin/user?pagesize=${params.pagesize}&page=${params.page + 1}&studentname=${params.studentname}&countryid=${
          params.countryid
        }&stateid=${params.stateid}&schoolid=${params.schoolid}`
      );
      setUsers(data?.data?.users);
      setError({ success: data?.isSuccess, error: data?.msg });
      setTotalUsers(data?.data?.totalUser);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const fetchRoles = async () => {
    try {
      const { data } = await axios.get('/admin/role');
      const formatRoles = data?.data.map((role) => ({ value: role._id, label: role.role }));
      setRoles(formatRoles);
    } catch (e) {
      console.log(e);
      setErrorRoles(e);
    }
  };

  const removeUsers = async (id) => {
    try {
      await axios.delete(`/admin/user/${id}`);
      success(t('users.action.removeSuccess'));
      fetchUsers();
    } catch (e) {
      errorMsg(t('users.action.removeFailed'));
      console.log(e);
    }
  };

  const handleLimitChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      pagesize: parseInt(event.target.value, 10),
      page: 0
    }));
  }, []);

  const handleOnPageChange = (event, newPage) => {
    setParams((pre) => ({
      ...pre,
      page: newPage
    }));
  };

  const handleSearchChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      studentname: event.target.value,
      page: 0
    }));
  }, []);

  const handleFilterChange = useCallback((event, newvalue) => {
    // console.log(event);
    // console.log(event, newvalue);
    const inputId = event.target.id;
    if (inputId.includes('cbo_country')) {
      setParams((pre) => ({
        ...pre,
        countryid: newvalue.id,
        page: 0
      }));
    } else if (inputId.includes('cbo_state')) {
      setParams((pre) => ({
        ...pre,
        stateid: newvalue.id,
        page: 0
      }));
    } else if (inputId.includes('cbo_school')) {
      setParams((pre) => ({
        ...pre,
        schoolid: newvalue.id,
        page: 0
      }));
    }
  }, []);

  const onOpenModal = useCallback((isOpen) => {
    setOpen(isOpen);
  }, []);

  const onOpenModalUser = useCallback((isOpen) => {
    setOpenModalUser(isOpen);
    reset();
  }, []);

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [params]);

  return {
    users,
    setParams,
    params,
    error,
    handleOnPageChange,
    handleLimitChange,
    handleSearchChange,
    totalUsers,
    removeUsers,
    open,
    onOpenModal,
    onOpenModalUser,
    openModalUser,
    setValue,
    setIdUser,
    idUser,
    handleSubmit,
    isSubmitting,
    formItems,
    control,
    watch,
    onSubmit,
    roles,
    errorRoles,
    setUserName,
    handleFilterChange,
    onSubmitBatch
  };
};
