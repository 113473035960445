import { Grid, Box, Card } from '@mui/material';
import { useCountries } from 'src/hooks/user/useCountries';
import CountryList from 'src/components/countries/CountryList';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ModalCountry } from 'src/components/countries/ModalCountry';
import PlusIcon from '../../icons/Plus';
import { countryFields } from '../../models/country';

export const CountriesContainer = () => {
  const {
    countries,
    params,
    handleOnPageChange,
    handleLimitChange,
    totalCountry,
    removeCountry,
    open,
    onOpenModal,
    idCountry,
    setIdCountry,
    onSubmit,
    handleSubmit,
    formItems,
    openModalCountry,
    onOpenModalCountry,
    setValue
  } = useCountries();

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          onClick={() => {
            onOpenModalCountry(true);
            setIdCountry(null);
          }}
        >
          {t('actions.add', { name: countryFields.country.label })}
        </Button>
      </Grid>
      <Card>
        <CountryList
          countries={countries}
          setValue={setValue}
          totalCountry={totalCountry}
          removeCountry={removeCountry}
          idCountry={idCountry}
          setIdCountry={setIdCountry}
          open={open}
          onOpenModal={onOpenModal}
          handleOnPageChange={handleOnPageChange}
          handleLimitChange={handleLimitChange}
          openModalCountry={openModalCountry}
          onOpenModalCountry={onOpenModalCountry}
          {...params}
        />
      </Card>
      <ModalCountry
        onSubmit={onSubmit}
        idCountry={idCountry}
        handleSubmit={handleSubmit}
        {...formItems}
        openModalCountry={openModalCountry}
        onOpenModalCountry={onOpenModalCountry}
      />
    </Box>
  );
};
