import { useTranslation } from 'react-i18next';
import { Box, Container } from '@mui/material';
import { ConfigsContainer } from 'src/containers/configs';
import { Helmet } from 'react-helmet';
// import useConfigs from '../../hooks/useCustomConfigs';

const Configs = () => {
  const { t } = useTranslation();
  // const { configs } = useConfigs();
  return (
    <>
      <Helmet>
        <title>{t('users.helmet')}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4
        }}
      >
        <Container maxWidth="xxl">
          <Box sx={{ mt: 3 }}>
            <ConfigsContainer />
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default Configs;
