import axios from 'axios';

const config = {
  baseURL: process.env.REACT_APP_BASE_URL || '/',
  header: { 'Content-Type': 'application/json', Accept: 'application/json' }
};
const instance = axios.create(config);

instance.interceptors.request.use(
  (req) => {
    req.headers.Authorization = `Bearer ${localStorage.getItem('access_token')}`;
    return req;
  },
  (err) => Promise.reject(err)
);

instance.interceptors.response.use(
  (response) => ({
    code: response.status,
    data: response.data
  }),
  (error) => {
    if (error.response) {
      const errorSerialized = {
        code: error.response.status,
        message: error.response.data.message
      };
      return Promise.reject(errorSerialized);
    }
    if (error.request)
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        message: 'No response was received. Something went wrong wih server. Please try again next time !'
      });
    return Promise.reject(error);
  }
);
export default instance;
