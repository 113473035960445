import { i18n } from '../i18n';
import StringField from './fields/stringField';
import IdField from './fields/idField';

const label = (field) => i18n.t(`form.fields.${field}`);
const placeholder = (field) => i18n.t(`form.placeholder.${field}`);
export const fields = {
  id: new IdField('id', 'id'),
  email: new StringField('email', label('email'), placeholder('email'), {
    min: 6
  }),
  token: new StringField('token', label('token'), placeholder('token'), {
    min: 6
  }),
  password: new StringField('password', label('password'), placeholder('password'), {
    required: true,
    min: 6,
    max: 64
  }),
  passwordConfirmation: new StringField(
    'passwordConfirmation',
    label('passwordConfirmation'),
    placeholder('passwordConfirmation'),
    {
      required: true,
      min: 6,
      max: 64
    }
  ),
  createdAt: new StringField('createdAt', label('createdAt'))
};

export default {
  fields,
  placeholder
};
