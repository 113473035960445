import { FormControl, FormLabel, Grid, TextField, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import { Controller } from 'react-hook-form';

const NumberField = (props) => {
  const {
    label,
    name,
    control,
    formState,
    layout = {},

    error,
    helperText,
    labelProps,
    ...others
  } = props;

  const { errors = {} } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: renderProps }) => (
        <FormControl fullWidth {...others} error={error || !!_get(errors, `${name}.message`)}>
          <Grid container {...layout.grid}>
            <Grid item {...layout.label}>
              {!!label && <FormLabel {...labelProps}>{label}</FormLabel>}
            </Grid>
            <Grid item {...layout.input}>
              <TextField
                fullWidth
                size="small"
                {...others}
                {...renderProps}
                type="text"
                value={renderProps.value}
                error={error || !!_get(errors, `${name}.message`)}
                onChange={(evt) => {
                  const { value } = evt.target;
                  const onlyNumberPattern = /^-?[0-9]*\.?[0-9]*$/;
                  if (value === '') {
                    renderProps.onChange('');
                  } else if (onlyNumberPattern.test(value)) {
                    renderProps.onChange(/^00/.test(value) ? '0' : value);
                  } else {
                    renderProps.onChange(renderProps.value || '');
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item {...layout.label} />
                <Grid item {...layout.input}>
                  <FormHelperText sx={{ mx: 0 }}>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
    />
  );
};

NumberField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  error: PropTypes.bool,
  inputProps: PropTypes.object,
  helperText: PropTypes.string,
  labelProps: PropTypes.object,
  layout: PropTypes.object
};

NumberField.defaultProps = {
  error: false,
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 12
    },
    input: {
      xs: 12
    }
  }
};

export default NumberField;
