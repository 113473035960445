import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginSuccess, loginFailure, forgotpasswordRequest } from 'src/redux/actions/auth';
import * as UserSelectors from 'src/redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { FormSchema } from '../models/schema';
import { fields } from '../models/forgotpassword';
import { useNotify } from './useNotify';

export const useForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(UserSelectors.getAuthUser);
  const error = useSelector(UserSelectors.getAuthError);
  const navigate = useNavigate();
  const { success, error: errorMsg } = useNotify();
  const { schema } = useMemo(() => new FormSchema(fields.id, [fields.username, fields.email]), []);

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      [fields.username.name]: '',
      [fields.email.name]: ''
    },
    resolver: schema
  });

  const { isSubmitting } = formState;

  const onForgotPassword = useCallback(
    async (formData) => {
      try {
        console.log(formData);
        const { data } = await dispatch(forgotpasswordRequest(formData));
        success(t('auth.login.resetPasswordSuccess'), { autoHideDuration: 10000 });
        dispatch(loginSuccess(data.data));
        navigate('/login', { replace: true });
      } catch (e) {
        errorMsg(t('auth.login.invalidUserAndEmail'));
        dispatch(loginFailure(e.message));
      }
    },
    [dispatch, navigate]
  );

  return {
    user,
    error,
    handleSubmit,
    control,
    formState,
    isSubmitting,
    onForgotPassword
  };
};
