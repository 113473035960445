import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';

import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useState } from 'react';

// const BorderLinearProgress = () =>
//   ({
//     root: {
//       height: 15,
//       borderRadius: 5
//     },
//     colorPrimary: {
//       backgroundColor: '#EEEEEE'
//     },
//     bar: {
//       borderRadius: 5,
//       backgroundColor: '#1a90ff'
//     }
//   }(LinearProgress));
export const ModalVideo = ({ openModalVideo, onOpenModalVideo, onSubmit, idVideo }) => {
  //   const [currentFile, setCurrentFile] = useState(undefined);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  //   const [progress, setProgress] = useState(0);
  //   const [message, setMessage] = useState(0);
  //   const [isError, setIsError] = useState(0);
  const { t } = useTranslation();

  function selectFile(event) {
    setSelectedFiles(event.target.files);
    console.log(event.target.files);
  }

  function upload() {
    // setCurrentFile(selectedFiles[0]);
    console.log(selectedFiles[0]);

    onSubmit(selectedFiles[0], (event) => {
      console.log(event);
    });
    //   .catch(() => {
    //     setProgress(0);
    //     setMessage('Could not upload the file!');
    //     setCurrentFile(undefined);
    //     setIsError(true);
    // });

    setSelectedFiles(undefined);
    return false;
  }

  // const bgmlist = [{ value: 1, label: 'BGM' }];
  return (
    <Dialog fullWidth maxWidth="md" open={openModalVideo} onClose={() => onOpenModalVideo(false)}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onOpenModalVideo(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {idVideo ? t('Videos.action.updateVideo') : t('videos.action.addVideo')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="mg20">
              <label htmlFor="btn-upload">
                <input
                  id="btn-upload"
                  name="btn-upload"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={selectFile}
                  accept=".mp4"
                />
                <Button className="btn-choose" variant="outlined" component="span">
                  Choose Files
                </Button>
              </label>
              <div className="file-name">
                {selectedFiles && selectedFiles.length > 0 ? selectedFiles[0].name : null}
              </div>
              <Button
                className="btn-upload"
                color="primary"
                variant="contained"
                component="span"
                disabled={!selectedFiles}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={upload}
              >
                Upload
              </Button>

              {/* <Typography variant="subtitle2" className={`upload-message ${isError ? 'error' : ''}`}>
                {message}
              </Typography> */}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOpenModalVideo(false)}>{t('videos.action.cancel')}</Button>
        {/* <Button onClick={handleSubmit(onSubmit)}>
          {idVideo ? t('Videos.action.save') : t('videos.action.create')}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};
ModalVideo.propTypes = {
  idVideo: PropTypes.string,
  openModalVideo: PropTypes.bool,
  onOpenModalVideo: PropTypes.func,
  onSubmit: PropTypes.func
};
