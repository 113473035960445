import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import _get from 'lodash/get';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { FormControl, FormLabel, Grid, TextField, FormHelperText } from '@mui/material';

const DateField = (props) => {
  const {
    label,
    name,
    control,
    formState,
    layout = {},

    error,
    helperText,
    labelProps,
    ...others
  } = props;

  const { errors = {} } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...fields } }) => (
        <FormControl fullWidth {...others} error={error || !!_get(errors, `${name}.message`)}>
          <Grid container {...layout.grid}>
            <Grid item {...layout.label}>
              {!!label && <FormLabel {...labelProps}>{label}</FormLabel>}
            </Grid>
            <Grid item {...layout.input}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  {...others}
                  {...fields}
                  onChange={onChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      helperText=""
                      error={error || !!_get(errors, `${name}.message`)}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item {...layout.label} />
                <Grid item {...layout.input}>
                  <FormHelperText sx={{ mx: 0 }}>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
    />
  );
};

DateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  error: PropTypes.bool,
  inputProps: PropTypes.object,
  helperText: PropTypes.string,
  labelProps: PropTypes.object,
  layout: PropTypes.object
};

DateField.defaultProps = {
  error: false,
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 12
    },
    input: {
      xs: 12
    }
  }
};

export default DateField;
