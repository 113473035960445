import {
  FormControlLabel,
  RadioGroup,
  Typography,
  Radio,
  Box,
  FormHelperText,
  FormControl,
  FormLabel
} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import _get from 'lodash/get';

const RadioField = ({
  name,
  control,
  formState,
  error,
  label,
  inputProps,
  helperText,
  options,
  labelStyle,
  ...props
}) => {
  const { errors = {} } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth {...props} error={error || !!_get(errors, `${name}.message`)}>
          {!!label && (
            <FormLabel
              style={{
                marginTop: -10,
                ...labelStyle
              }}
            >
              {label}
            </FormLabel>
          )}
          <RadioGroup {...props} {...field}>
            {options &&
              options.map((item) => (
                <FormControlLabel
                  control={<Radio color="primary" />}
                  key={item.value}
                  label={
                    <Box sx={{ ml: 2 }}>
                      <Typography color="textPrimary" variant="subtitle2">
                        {item.label}
                      </Typography>
                    </Box>
                  }
                  value={item.value}
                />
              ))}
          </RadioGroup>
          <FormHelperText>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

RadioField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,

  error: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,

  inputProps: PropTypes.object,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  labelStyle: PropTypes.object
};

RadioField.defaultProps = {
  error: false,
  required: false,
  autoFocus: false,
  disabled: false,

  inputProps: null,
  defaultValue: '',
  type: '',
  label: '',
  margin: 'normal',
  variant: 'outlined',
  helperText: '',
  autoComplete: ''
};

export default RadioField;
