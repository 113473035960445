import { Avatar, Box, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@mui/material';
import getLabel from 'src/utils/getLabel';
import { Loading } from 'src/components/LoadingScreen';
import PropTypes from 'prop-types';
import InputField from 'src/components/FormControls/InputField';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { fields } from '../../models/user';

export const UserDetail = ({ user, loading, ...formItems }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={3}>
      <Grid item lg={4} md={6} xl={3} xs={12}>
        <Card>
          <CardContent>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center'
              }}
            >
              <Box
                sx={{
                  p: 1,
                  border: (theme) => `1px dashed ${theme.palette.divider}`,
                  borderRadius: '50%'
                }}
              >
                <Avatar
                  src={user?.avatar}
                  sx={{
                    height: 100,
                    width: 100
                  }}
                />
              </Box>
              <Typography color="textPrimary" sx={{ mt: 1 }} variant="subtitle2">
                {user.fullName}
              </Typography>
              <Typography color="textSecondary" variant="body2">
                {getLabel(user?.role?.role || '')}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item lg={8} md={6} xl={9} xs={12}>
        <Card>
          <CardHeader title={t('users.detail.title')} />
          <Divider />
          <CardContent sx={{ position: 'relative' }}>
            <Loading loading={loading} />
            <Grid container columnSpacing={4}>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.username.label}
                  value={user.username || ''}
                  studentId
                  disabled
                  name={fields.username.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.fullName.label}
                  value={user.fullName || ''}
                  studentId
                  disabled
                  name={fields.fullName.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.trainingId.label}
                  value={user.trainingId || ''}
                  studentId
                  disabled
                  name={fields.trainingId.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  name={fields.gender.name}
                  {...formItems}
                  label={fields.gender.label}
                  value={user.gender || ''}
                  studentId
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.joinDate.label}
                  value={moment(user.joinDate).format('DD/MM/YYYY') || ''}
                  studentId
                  disabled
                  name={fields.joinDate.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.activationDate.label}
                  value={moment(user.activationDate || user.joinDate).format('DD/MM/YYYY') || ''}
                  studentId
                  disabled
                  name={fields.activationDate.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.expireDate.label}
                  value={moment(user.expireDate).format('DD/MM/YYYY') || ''}
                  studentId
                  disabled
                  name={fields.expireDate.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.birthday.label}
                  value={moment(user.birthday).format('DD/MM/YYYY') || ''}
                  studentId
                  disabled
                  name={fields.birthday.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.trainingReasonCode.label}
                  value={user.trainingReason?.code || ''}
                  studentId
                  disabled
                  name={fields.trainingReasonCode.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.trainingReasonName.label}
                  value={user.trainingReason?.name || ''}
                  studentId
                  disabled
                  name={fields.trainingReasonName.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.countryCode.label}
                  value={user.country?.code || ''}
                  studentId
                  disabled
                  name={fields.countryCode.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.countryName.label}
                  value={user.country?.name || ''}
                  studentId
                  disabled
                  name={fields.countryName.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.stateCode.label}
                  value={user.state?.code || ''}
                  studentId
                  disabled
                  name={fields.stateCode.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.stateName.label}
                  value={user.state?.name || ''}
                  studentId
                  disabled
                  name={fields.stateName.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.schoolCode.label}
                  value={user.school?.code || ''}
                  studentId
                  disabled
                  name={fields.schoolCode.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.schoolName.label}
                  value={user.school?.name || ''}
                  studentId
                  disabled
                  name={fields.schoolName.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  {...formItems}
                  label={fields.schoolCategory.label}
                  value={user.school?.typeOfSchool.name || ''}
                  studentId
                  disabled
                  name={fields.schoolCategory.name}
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  name={fields.remarks.name}
                  {...formItems}
                  label={fields.remarks.label}
                  value={user.remarks || ''}
                  studentId
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  name={fields.note.name}
                  {...formItems}
                  label={fields.note.label}
                  value={user.note || ''}
                  studentId
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  name={fields.status.name}
                  {...formItems}
                  label={fields.status.label}
                  value={t(`users.status.${user.status}`)}
                  studentId
                  disabled
                />
              </Grid>
              <Grid item xs={4}>
                <InputField
                  name={fields.holdingNote.name}
                  {...formItems}
                  label={fields.holdingNote.label}
                  value={user.holdingNote || ''}
                  studentId
                  disabled
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
        </Card>
      </Grid>
    </Grid>
  );
};

UserDetail.propTypes = {
  user: PropTypes.object,
  loading: PropTypes.bool
};
