/* eslint-disable react/prop-types */
import Slider from 'react-slick';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Color } from 'src/theme/color';

export const SliderItem = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('480')]: {
    padding: 10
  }
}));

const PreArrows = ({ className, onClick }) => (
  <ArrowBackIcon
    onClick={onClick}
    className={className}
    sx={{
      height: 50,
      width: 50,
      marginLeft: -3,
      borderRadius: '50%',
      backgroundColor: Color.white,
      padding: 1.5,
      color: Color.black,
      ':hover': {
        color: Color.black,
        backgroundColor: Color.white
      }
    }}
  />
);

const NextArrows = ({ className, onClick }) => (
  <ArrowBackIcon
    onClick={onClick}
    className={className}
    sx={{
      height: 50,
      width: 50,
      borderRadius: '50%',
      backgroundColor: Color.white,
      padding: 1.5,
      color: Color.black,
      transform: 'rotate(180deg)',
      marginRight: -3,
      marginTop: -3.4,
      ':hover': {
        color: Color.black,
        backgroundColor: Color.white
      }
    }}
  />
);

export const DashboardSlider = ({ children }) => {
  const settings = {
    dots: false,
    focusOnSelect: true,
    infinite: false,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrows />,
    prevArrow: <PreArrows />,
    responsive: [
      {
        breakpoint: 1536,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          fade: true,
          arrows: false,
          speed: 700,
          slidesToScroll: 1
        }
      }
    ]
  };
  return <Slider {...settings}>{children}</Slider>;
};

DashboardSlider.propTypes = {
  children: PropTypes.array
};
