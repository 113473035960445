/* eslint-disable react/prop-types */
import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// import EditIcon from '@mui/icons-material/Edit';

const ConfigList = ({ data, open, onOpenModal }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell>Value</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow key="HERO_IMAGE">
            <TableCell>Hero image</TableCell>
            <TableCell>{data.HERO_IMAGE || ''}</TableCell>
            <TableCell>123</TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Dialog
        open={open}
        onClose={() => onOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('dialog.title')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => onOpenModal(false)}> {t('dialog.noButton')}</Button>
          <Button
            onClick={() => {
              // removePrograms(idProgram);
              onOpenModal(false);
            }}
            autoFocus
          >
            {t('dialog.yesButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
ConfigList.propTypes = {
  programs: PropTypes.shape({
    key: PropTypes.string
  }),

  open: PropTypes.bool,
  onOpenModal: PropTypes.func
};
export default ConfigList;
