import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import InputField from '../../components/FormControls/InputField';
import PasswordField from '../../components/FormControls/PasswordField';
import { fields } from '../../models/login';
import { useAuth } from '../../hooks/useAuth';

const LoginForm = (props) => {
  const { t } = useTranslation();
  const { control, formState, isSubmitting, onLogin, handleSubmit } = useAuth();

  return (
    <form noValidate {...props} onSubmit={handleSubmit(onLogin)}>
      <InputField
        autoFocus
        fullWidth
        label={fields.username.label}
        name={fields.username.name}
        placeholder={fields.username.placeholder}
        inputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PhoneIphoneOutlinedIcon />
            </InputAdornment>
          )
        }}
        control={control}
        formState={formState}
      />
      <PasswordField
        required
        fullWidth
        label={fields.password.label}
        name={fields.password.name}
        placeholder={fields.password.placeholder}
        autoComplete="current-password"
        control={control}
        formState={formState}
      />
      <Box sx={{ mt: 2 }}>
        <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
          {t('auth.login.submit')}
        </Button>
        <Link href="/forgotpassword">Forgot Password</Link>
      </Box>
    </form>
  );
};

export default LoginForm;
