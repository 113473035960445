import axios from 'src/utils/axios';
import { useEffect, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormSchema } from '../../models/schema';
import { fields } from '../../models/user';

export const useUserDetail = (id) => {
  const [user, setUser] = useState({});
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);
  const [programs, setPrograms] = useState([]);
  const [errorPrograms, setErrorPrograms] = useState();
  const { schema } = useMemo(
    () =>
      new FormSchema(fields.id, [
        fields.username,
        fields.trainingId,
        fields.role,
        fields.fullName,
        fields.gender,
        fields.birthday,
        fields.joinDate,
        fields.countryCode,
        fields.countryName,
        fields.stateCode,
        fields.stateName,
        fields.schoolCode,
        fields.schoolName,
        fields.schoolCategory,
        fields.trainingReasonCode,
        fields.trainingReasonName,
        fields.remarks,
        fields.note,
        fields.status,
        fields.expireDate,
        fields.activationDate,
        fields.holdingNote
      ]),
    []
  );
  const { control, formState } = useForm({
    defaultValues: {
      [fields.username.name]: '',
      [fields.trainingId.name]: '',
      [fields.role.name]: '',
      [fields.fullName.name]: '',
      [fields.gender.name]: '',
      [fields.birthday.name]: '',
      [fields.joinDate.name]: '',
      [fields.countryCode.name]: '',
      [fields.countryName.name]: '',
      [fields.stateCode.name]: '',
      [fields.stateName.name]: '',
      [fields.schoolCode.name]: '',
      [fields.schoolName.name]: '',
      [fields.schoolCategory.name]: '',
      [fields.trainingReasonCode.name]: '',
      [fields.trainingReasonName.name]: '',
      [fields.remarks.name]: '',
      [fields.note.name]: '',
      [fields.status.name]: 0,
      [fields.expireDate.name]: '',
      [fields.activationDate.name]: '',
      [fields.holdingNote.name]: ''
    },
    resolver: schema
  });

  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const fetchUserDetail = async () => {
    try {
      const { data } = await axios.get(`/admin/user/${id}`);
      if (data) {
        setLoading(false);
        setUser(data?.data);
        setError({ success: data?.isSuccess, error: data?.msg });
      }
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const fetchProgramByUser = async () => {
    try {
      const { data } = await axios.get(`admin/programnew/user/${id}`);
      if (data) {
        setLoading(false);
        setPrograms(data?.data);
        setErrorPrograms({ success: data?.isSuccess, error: data?.msg });
      }
    } catch (e) {
      console.log(e);
      setErrorPrograms(e);
    }
  };

  useEffect(() => {
    fetchUserDetail();
    fetchProgramByUser();
  }, []);

  return { user, error, loading, programs, errorPrograms, isSubmitting, formItems };
};
