import { Avatar, Box, Divider, Drawer, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import getLabel from 'src/utils/getLabel';
import { useSelector } from 'react-redux';
import * as UserSelectors from 'src/redux/selectors/user';
import { useUsersAdmin } from 'src/hooks/user/useUsersAdmin';
import { fields } from 'src/models/user';
import { ModalUserAdmin } from 'src/components/users/ModalUserAdmin';

import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PsychologyIcon from '@mui/icons-material/Psychology';
// import EditIcon from '@mui/icons-material/Edit';
import Users from '../../icons/Users';
// import Logo from '../Logo';
import NavSection from '../navbar/NavSection';
import Scrollbar from '../Scrollbar';
import FolderOpenIcon from '../../icons/FolderOpen';

const companySections = (t) => [
  {
    title: t('sidebar.management'),
    items: [
      {
        title: t('sidebar.users'),
        path: '/admin/user',
        icon: <Users fontSize="small" />
      },
      {
        title: t('sidebar.programs'),
        path: '/admin/program',
        icon: <FolderOpenIcon fontSize="small" />
      },
      {
        title: t('sidebar.programlogs'),
        path: '/admin/programlogs',
        icon: <FolderOpenIcon fontSize="small" />
      },
      {
        title: t('sidebar.videos'),
        path: '/admin/videos',
        icon: <PlayCircleIcon fontSize="small" />
      },
      {
        title: t('sidebar.trainingsignal'),
        path: '/admin/trainingsignals',
        icon: <PsychologyIcon fontSize="small" />
      }
      // ,
      // {
      //   title: 'Config',
      //   path: '/admin/configs',
      //   icon: <EditIcon fontSize="small" />
      // }
    ]
  }
];
const DashboardSidebar = (props) => {
  const { t } = useTranslation();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const sections = useMemo(() => companySections(t), []);
  const user = useSelector(UserSelectors.getAuthUser);
  const userId = useSelector(UserSelectors.getCurrentId);
  const {
    setIdUser,
    onOpenModalUserAdmin,
    setValue,
    openModalUserAdmin,
    idUser,
    onSubmitAdmin,
    handleSubmit,
    formItems,
    setUserName,
    user1
  } = useUsersAdmin(userId);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);
  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
        >
          <RouterLink to="/">
            {/* <Logo
              white
              sx={{
                height: 40,
                width: 40
              }}
            /> */}
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2
            }}
          >
            <Avatar
              // src={user.avatar}
              sx={{
                cursor: 'pointer',
                height: 48,
                width: 48
              }}
              onClick={() => {
                onOpenModalUserAdmin(true);
                setIdUser(userId);
                setUserName(user1.username);
                setValue(fields.username.name, user1.username);
                setValue(fields.password.name, user1.password);
                setValue(fields.fullName.name, user1.fullName);
                setValue(fields.role.name, user1.role.id);
                setValue(fields.gender.name, user1.gender);
                setValue(fields.birthday.name, user1.birthday);
                setValue(fields.joinDate.name, user1.joinDate);
                setValue(fields.remarks.name, user1.remarks);
                setValue(fields.note.name, user1.note);
                setValue(fields.phone.name, user1.phone);
              }}
            />
            <ModalUserAdmin
              openModalUserAdmin={openModalUserAdmin}
              onOpenModalUserAdmin={onOpenModalUserAdmin}
              onSubmitAdmin={onSubmitAdmin}
              handleSubmit={handleSubmit}
              idUser={idUser}
              setUserName={setUserName}
              {...formItems}
            />
            <Box sx={{ ml: 2 }}>
              <Typography color="textPrimary" variant="subtitle2">
                {user.username}
              </Typography>

              <Typography color="textSecondary" variant="body2">
                {getLabel(user.fullname)}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  open: PropTypes.bool,
  onOpenModal: PropTypes.func,
  onOpenModalUserAdmin: PropTypes.func,
  idUser: PropTypes.string,
  setIdUser: PropTypes.func,
  onSubmitAdmin: PropTypes.func,
  roles: PropTypes.array,
  watch: PropTypes.func,
  setUserName: PropTypes.func,
  handleSubmit: PropTypes.func,
  fetchUserAdmin: PropTypes.func,
  user1: PropTypes.object
};

export default DashboardSidebar;
