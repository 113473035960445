import { Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { programFields } from 'src/models/program';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
// import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';

const ProgramList = ({
  programs,
  page,
  pagesize,
  handleOnPageChange,
  handleLimitChange,
  totalPrograms,
  removePrograms,
  open,
  onOpenModal,
  idProgram,
  setIdProgram,
  onOpenModalProgram,
  setValue
}) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{programFields.name.label}</TableCell>
            <TableCell>{programFields.student.label}</TableCell>
            <TableCell>School</TableCell>
            <TableCell>{programFields.videos.label}</TableCell>
            <TableCell>{programFields.startProgram.label}</TableCell>
            <TableCell>{programFields.endProgram.label}</TableCell>
            {/* <TableCell>{programFields.repeat.label}</TableCell> */}
            <TableCell>{programFields.status.label}</TableCell>
            {/* <TableCell>PDF Result</TableCell> */}
            <TableCell>{programFields.action.label}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {programs &&
            programs.map((program) => (
              <TableRow key={program._id}>
                <TableCell>{program.title}</TableCell>
                <TableCell>{program.users?.fullName}</TableCell>
                <TableCell>{program.schools?.name}</TableCell>
                <TableCell>{program.playlist.length}</TableCell>
                <TableCell> {program.start && moment(program.start).format('DD/MM/YYYY hh:mm A')}</TableCell>
                <TableCell> {program.end && moment(program.end).format('DD/MM/YYYY hh:mm A')}</TableCell>
                {/* <TableCell> {program.repeat}</TableCell> */}
                <TableCell>{program.title && t(`programs.status.${program.flag}`)}</TableCell>
                {/* <TableCell> {program.pdf && <Link href={program.pdf}>Link</Link>}</TableCell> */}
                <TableCell>
                  {program.title && (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          aria-label="edit"
                          onClick={() => {
                            onOpenModalProgram(true);
                            setIdProgram(program._id);
                            setValue(programFields.title.name, program.title);
                            setValue(programFields.owner.name, program.users?._id);
                            setValue(programFields.playlist.name, program.playlist);
                            setValue(programFields.flag.name, program.flag);
                            setValue(programFields.bgm.name, program.bgm);
                            setValue(programFields.audio.name, program.audiomaterials?._id);
                            setValue(programFields.totalProgramDuration.name, program.totalProgramDuration);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          aria-label="delete"
                          onClick={() => {
                            onOpenModal(true);
                            setIdProgram(program._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalPrograms}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={pagesize}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      <Dialog
        open={open}
        onClose={() => onOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('dialog.title')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => onOpenModal(false)}> {t('dialog.noButton')}</Button>
          <Button
            onClick={() => {
              removePrograms(idProgram);
              onOpenModal(false);
            }}
            autoFocus
          >
            {t('dialog.yesButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
ProgramList.propTypes = {
  programs: PropTypes.array,
  page: PropTypes.number,
  pagesize: PropTypes.number,
  totalPrograms: PropTypes.number,
  open: PropTypes.bool,
  onOpenModal: PropTypes.func,
  onOpenModalProgram: PropTypes.func,
  idProgram: PropTypes.string,
  setIdProgram: PropTypes.func,
  removePrograms: PropTypes.func,
  handleOnPageChange: PropTypes.func,
  setValue: PropTypes.func,
  handleLimitChange: PropTypes.func
};
export default ProgramList;
