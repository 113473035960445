import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputField from 'src/components/FormControls/InputField';
import { fields } from 'src/models/user';
import DesktopDateField from 'src/components/FormControls/DesktopDateField';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RadioField from 'src/components/FormControls/RadioField';
import { useCountries } from 'src/hooks/user/useCountries';
import { useStates } from 'src/hooks/user/useStates';
import { useTrainingReasons } from 'src/hooks/user/useTrainingReasons';
import { useSchools } from 'src/hooks/user/useSchools';
import { useEffect } from 'react';
import PasswordField from '../FormControls/PasswordField';
import SelectField from '../FormControls/SelectField';

export const ModalUser = ({
  openModalUser,
  onOpenModalUser,
  onSubmit,
  handleSubmit,
  watch,
  idUser,
  roles,
  setUserName,
  ...formItems
}) => {
  const { t } = useTranslation();
  const values = watch();

  const { countryList } = useCountries();
  const { stateList } = useStates(values.country);
  const { schoolList } = useSchools(values.state);
  const { trainingReasonList } = useTrainingReasons(values.trainingReason);

  const countryCode = countryList.filter((country) => country.value === values.country)[0]?.code;
  const stateCode = stateList.filter((state) => state.value === values.state)[0]?.code;
  const schoolCode = schoolList.filter((school) => school.value === values.school)[0]?.code;

  const valueStudentID = `${countryCode || ''}${stateCode ? `-${stateCode}` : ''}${schoolCode ? `-${schoolCode}` : ''}${
    values.trainingId ? `-${values.trainingId}` : ''
  }`;

  useEffect(() => {
    if (valueStudentID) {
      setUserName(valueStudentID);
    }
  }, [valueStudentID]);

  return (
    <Dialog fullWidth maxWidth="md" open={openModalUser} onClose={() => onOpenModalUser(false)}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onOpenModalUser(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {idUser ? t('users.action.updateUser') : t('users.action.addUser')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField
              {...formItems}
              name={fields.username.name}
              label={fields.studentId.label}
              value={valueStudentID}
              studentId
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField {...formItems} name={fields.role.name} label={fields.role.label} options={roles} />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.fullName.name} label={fields.fullName.label} />
          </Grid>
          <Grid item xs={6}>
            <RadioField
              required
              autoFocus
              row
              label={fields.gender.label}
              name={fields.gender.name}
              options={fields.gender.options}
              {...formItems}
            />
          </Grid>
          <Grid item xs={12}>
            <DesktopDateField {...formItems} name={fields.birthday.name} label={fields.birthday.label} />
          </Grid>
          <Grid item xs={6}>
            <DesktopDateField {...formItems} name={fields.joinDate.name} label={fields.joinDate.label} />
          </Grid>
          <Grid item xs={6}>
            <DesktopDateField {...formItems} name={fields.activationDate.name} label={fields.activationDate.label} />
          </Grid>
          <Grid item xs={6}>
            <DesktopDateField {...formItems} name={fields.expireDate.name} label={fields.expireDate.label} />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} required name={fields.trainingId.name} label={fields.trainingId.label} />
          </Grid>
          <Grid item xs={6}>
            <PasswordField
              {...formItems}
              required={!idUser}
              autoComplete="current-password"
              name={idUser ? fields.resetPassword.name : fields.password.name}
              label={idUser ? fields.resetPassword.label : fields.password.label}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={fields.trainingReason.name}
              label={fields.trainingReason.label}
              options={trainingReasonList}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={fields.country.name}
              label={fields.country.label}
              options={countryList}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={fields.state.name}
              label={fields.state.label}
              disabled={!values.country}
              options={stateList}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={fields.school.name}
              label={fields.school.label}
              disabled={!values.state}
              options={schoolList}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.remarks.name} label={fields.remarks.label} />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={fields.note.name} label={fields.note.label} />
          </Grid>
          <Grid item xs={6}>
            <RadioField
              autoFocus
              row
              label={fields.status.label}
              name={fields.status.name}
              options={fields.status.options}
              {...formItems}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField {...formItems} name={fields.holdingNote.name} label={fields.holdingNote.label} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOpenModalUser(false)}>{t('users.action.cancel')}</Button>
        <Button onClick={handleSubmit(onSubmit)}>{idUser ? t('users.action.save') : t('users.action.create')}</Button>
      </DialogActions>
    </Dialog>
  );
};
ModalUser.propTypes = {
  idUser: PropTypes.string,
  openModalUser: PropTypes.bool,
  roles: PropTypes.array,
  watch: PropTypes.func,
  onOpenModalUser: PropTypes.func,
  setUserName: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func
};
