import axios from 'src/utils/axios';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNotify } from '../useNotify';
import { FormSchema } from '../../models/schema';
import { fields } from '../../models/trainingReason';

const initialParams = {
  pagesize: 10,
  page: 0
};

export const useTrainingReasons = () => {
  const [trainingReasons, setTrainingReasons] = useState([]);
  const [totalTrainingReason, setTotalTrainingReason] = useState(0);
  const [idTrainingReason, setIdTrainingReason] = useState('');
  const [error, setError] = useState();
  const [params, setParams] = useState(initialParams);

  const [trainingReasonList, setTrainingReasonList] = useState([]);
  const [errorTrainingReasonList, setErrorTrainingReasonList] = useState();

  const [open, setOpen] = useState(false);
  const [openModalTrainingReason, setOpenModalTrainingReason] = useState(false);

  const { t } = useTranslation();
  const { success, error: errorMsg } = useNotify();

  const { schema } = useMemo(() => new FormSchema(fields.id, [fields.name, fields.code]), []);

  const { handleSubmit, control, formState, watch, reset, setValue } = useForm({
    defaultValues: {
      [fields.name.name]: '',
      [fields.code.name]: ''
    },
    resolver: schema
  });

  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const onSubmit = useCallback(async (formData) => {
    try {
      if (idTrainingReason) {
        await axios.patch(`admin/trainingreason/${idTrainingReason}`, formData);
        success(t('actions.updateSuccess', { name: fields.trainingReason.label }));
      } else {
        await axios.post('admin/trainingreason', formData);
        success(t('actions.createSuccess', { name: fields.trainingReason.label }));
      }
      reset();
      fetchTrainingReasons();
      setOpenModalTrainingReason(false);
    } catch (e) {
      console.log(e);
      if (idTrainingReason) {
        errorMsg(t('actions.updateFailed', { name: fields.trainingReason.label }));
      } else {
        errorMsg(t('actions.createFailed', { name: fields.trainingReason.label }));
      }
    }
  });

  const fetchTrainingReasons = async () => {
    try {
      const { data } = await axios.get(`/admin/trainingreason?pagesize=${params.pagesize}&page=${params.page + 1}`);
      setTrainingReasons(data?.data?.trainingReasons);
      setError({ success: data?.isSuccess, error: data?.msg });
      setTotalTrainingReason(data?.data?.totalTrainingReason);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const fetchTrainingReasonList = async () => {
    try {
      const { data } = await axios.get('/admin/trainingreasonlist');
      const formatTrainingReasonList = data?.data.map((trainingReason) => ({
        value: trainingReason._id,
        id: trainingReason._id,
        label: trainingReason.name
      }));
      setTrainingReasonList(formatTrainingReasonList);
    } catch (e) {
      console.log(e);
      setErrorTrainingReasonList(e);
    }
  };

  const removeTrainingReason = async (id) => {
    try {
      await axios.delete(`/admin/trainingreason/${id}`);
      success(t('actions.removeSuccess', { name: fields.trainingReason.label }));
      fetchTrainingReasons();
    } catch (e) {
      errorMsg(t('actions.removeFailed', { name: fields.trainingReason.label }));
      console.log(e);
    }
  };

  const handleLimitChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      pagesize: parseInt(event.target.value, 10),
      page: 0
    }));
  }, []);

  const handleOnPageChange = (event, newPage) => {
    setParams((pre) => ({
      ...pre,
      page: newPage
    }));
  };

  const onOpenModal = useCallback((isOpen) => {
    setOpen(isOpen);
  }, []);

  const onOpenModalTrainingReason = useCallback((isOpen) => {
    setOpenModalTrainingReason(isOpen);
    reset();
  }, []);

  useEffect(() => {
    fetchTrainingReasonList();
  }, []);

  useEffect(() => {
    fetchTrainingReasons();
  }, [params]);

  return {
    trainingReasons,
    setParams,
    params,
    error,
    handleOnPageChange,
    handleLimitChange,
    totalTrainingReason,
    removeTrainingReason,
    open,
    onOpenModal,
    onOpenModalTrainingReason,
    openModalTrainingReason,
    setValue,
    setIdTrainingReason,
    idTrainingReason,
    handleSubmit,
    isSubmitting,
    formItems,
    control,
    watch,
    onSubmit,
    trainingReasonList,
    errorTrainingReasonList
  };
};
