import { i18n } from '../i18n';
import StringField from './fields/stringField';
import IdField from './fields/idField';
import EnumeratorField from './fields/enumeratorField';

const label = (field) => i18n.t(`form.fields.${field}`);
const placeholder = (field) => i18n.t(`form.placeholder.${field}`);
// 0 là inactive, 1 là active, 2 holding
export const StatusOptions = ['0', '1', '2'].map((value) => ({
  value,
  label: i18n.t(`form.status.${value}`)
}));
export const fields = {
  id: new IdField('id', 'id'),
  username: new StringField('username', label('username'), placeholder('username')),
  password: new StringField('password', label('password'), placeholder('password'), {
    required: true,
    min: 6,
    max: 64
  }),
  resetPassword: new StringField('resetPassword', label('resetPassword'), placeholder('resetPassword')),
  trainingId: new StringField('trainingId', label('trainingId'), placeholder('trainingId'), {
    required: true
  }),
  fullName: new StringField('fullName', label('fullName'), placeholder('fullName'), { max: 24 }),
  gender: new EnumeratorField(
    'gender',
    label('gender'),
    [
      { value: 'male', label: label('male') },
      { value: 'female', label: label('female') }
    ],
    {
      required: true
    }
  ),
  birthday: new StringField('birthday', label('birthday')),
  joinDate: new StringField('joinDate', label('joinDate')),
  expireDate: new StringField('expireDate', label('expireDate')),
  activationDate: new StringField('activationDate', label('activationDate')),
  country: new StringField('country', label('country'), placeholder('country'), {
    required: true
  }),
  countryCode: new StringField('countryCode', label('countryCode')),
  countryName: new StringField('countryName', label('countryName')),
  state: new StringField('state', label('state'), placeholder('state'), {
    required: true
  }),
  stateCode: new StringField('stateCode', label('stateCode')),
  stateName: new StringField('stateName', label('stateName')),
  school: new StringField('school', label('school'), placeholder('school'), {
    required: true
  }),
  schoolCode: new StringField('schoolCode', label('schoolCode')),
  schoolName: new StringField('schoolName', label('schoolName')),
  schoolCategory: new StringField('schoolCategory', label('schoolCategory')),
  trainingReason: new StringField('trainingReason', label('trainingReason'), placeholder('trainingReason'), {
    required: true
  }),
  trainingReasonCode: new StringField('trainingReasonCode', label('trainingReasonCode')),
  trainingReasonName: new StringField('trainingReasonName', label('trainingReasonName')),

  remarks: new StringField('remarks', label('remarks')),
  note: new StringField('note', label('note')),
  holdingNote: new StringField('holdingNote', label('holdingNote')),
  status: new EnumeratorField('status', label('status'), [
    { value: '0', label: label('inActive') },
    { value: '1', label: label('active') },
    { value: '2', label: label('holding') }
  ]),
  role: new StringField('role', label('role')),
  action: new StringField('action', label('action')),
  studentId: new StringField('studentId', label('studentId')),
  clientId: new StringField('clientId', label('clientId')),
  createdAt: new StringField('createdAt', label('createdAt')),
  phone: new StringField('phone', label('phone'))
};

export default {
  fields,
  placeholder
};
