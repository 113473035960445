import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useResetPassword } from 'src/hooks/useResetPassword';
import InputField from '../../components/FormControls/InputField';
import PasswordField from '../../components/FormControls/PasswordField';
import { fields } from '../../models/resetpassword';

const ResetPasswordForm = (props) => {
  const { t } = useTranslation();
  const { control, formState, isSubmitting, onResetPassword, handleSubmit } = useResetPassword(props);
  return (
    <form noValidate {...props} onSubmit={handleSubmit(onResetPassword)}>
      <InputField
        fullWidth
        label={fields.email.label}
        name={fields.email.name}
        control={control}
        formState={formState}
        value={fields.email.value}
      />
      <InputField
        fullWidth
        label={fields.token.label}
        name={fields.token.name}
        control={control}
        formState={formState}
        value={fields.token.value}
      />
      <PasswordField
        autoFocus
        required
        fullWidth
        label={fields.password.label}
        name={fields.password.name}
        placeholder={fields.password.placeholder}
        autoComplete="current-password"
        control={control}
        formState={formState}
      />
      <PasswordField
        required
        fullWidth
        label={fields.passwordConfirmation.label}
        name={fields.passwordConfirmation.name}
        placeholder={fields.passwordConfirmation.placeholder}
        autoComplete="current-password"
        control={control}
        formState={formState}
      />
      <Box sx={{ mt: 2 }}>
        <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
          {t('auth.login.resetpassword')}
        </Button>
      </Box>
    </form>
  );
};

export default ResetPasswordForm;
