import { FormControl, FormLabel, Grid, TextField, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import _get from 'lodash/get';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';

const CssTextField = styled(TextField)({
  'input.Mui-disabled': {
    '-webkit-text-fill-color': 'rgba(0, 0, 0 )'
  }
});
const InputField = (props) => {
  const {
    label,
    name,
    control,
    formState,
    layout = {},
    error,
    helperText,
    labelProps,
    value,
    studentId,
    ...others
  } = props;

  const { errors = {} } = formState;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: renderProps }) => (
        <FormControl fullWidth {...others} error={error || !!_get(errors, `${name}.message`)}>
          <Grid container {...layout.grid}>
            <Grid item {...layout.label}>
              {!!label && <FormLabel {...labelProps}>{label}</FormLabel>}
            </Grid>
            <Grid item {...layout.input}>
              {studentId ? (
                <CssTextField
                  fullWidth
                  size="small"
                  {...others}
                  {...renderProps}
                  value={value}
                  error={error || !!_get(errors, `${name}.message`)}
                />
              ) : (
                <TextField
                  fullWidth
                  size="small"
                  {...others}
                  {...renderProps}
                  value={renderProps.value}
                  error={error || !!_get(errors, `${name}.message`)}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item {...layout.label} />
                <Grid item {...layout.input}>
                  <FormHelperText sx={{ mx: 0 }}>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
    />
  );
};

InputField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  studentId: PropTypes.bool,
  error: PropTypes.bool,
  inputProps: PropTypes.object,
  helperText: PropTypes.string,
  labelProps: PropTypes.object,
  layout: PropTypes.object
};

InputField.defaultProps = {
  error: false,
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 12
    },
    input: {
      xs: 12
    }
  }
};

export default InputField;
