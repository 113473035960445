import axios from 'src/utils/axios';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNotify } from '../useNotify';
import { FormSchema } from '../../models/schema';
import { countryFields } from '../../models/country';

const initialParams = {
  pagesize: 10,
  page: 0
};

export const useCountries = () => {
  const [countries, setCountries] = useState([]);
  const [totalCountry, setTotalCountry] = useState(0);
  const [idCountry, setIdCountry] = useState('');
  const [error, setError] = useState();
  const [params, setParams] = useState(initialParams);

  const [countryList, setCountryList] = useState([]);
  const [errorCountryList, setErrorCountryList] = useState();

  const [open, setOpen] = useState(false);
  const [openModalCountry, setOpenModalCountry] = useState(false);

  const { t } = useTranslation();
  const { success, error: errorMsg } = useNotify();

  const { schema } = useMemo(() => new FormSchema(countryFields.id, [countryFields.name, countryFields.code]), []);
  const [countryListAll, setCountryListAll] = useState([]);
  const { handleSubmit, control, formState, watch, reset, setValue } = useForm({
    defaultValues: {
      [countryFields.name.name]: '',
      [countryFields.code.name]: ''
    },
    resolver: schema
  });

  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const onSubmit = useCallback(async (formData) => {
    try {
      if (idCountry) {
        await axios.patch(`admin/country/${idCountry}`, formData);
        success(t('actions.updateSuccess', { name: countryFields.country.label }));
      } else {
        await axios.post('admin/country', formData);
        success(t('actions.createSuccess', { name: countryFields.country.label }));
      }
      reset();
      fetchCountries();
      setOpenModalCountry(false);
    } catch (e) {
      console.log(e);
      if (idCountry) {
        errorMsg(t('actions.updateFailed', { name: countryFields.country.label }));
      } else {
        errorMsg(t('actions.createFailed', { name: countryFields.country.label }));
      }
    }
  });

  const fetchCountries = async () => {
    try {
      const { data } = await axios.get(`/admin/country?pagesize=${params.pagesize}&page=${params.page + 1}`);
      setCountries(data?.data?.countries);
      setError({ success: data?.isSuccess, error: data?.msg });
      setTotalCountry(data?.data?.totalCountry);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const fetchCountryList = async () => {
    try {
      const { data } = await axios.get('/admin/countrylist');
      const formatCountryList = data?.data.map((country) => ({
        value: country._id,
        label: country.name,
        code: country.code
      }));
      setCountryList(formatCountryList);
    } catch (e) {
      console.log(e);
      setErrorCountryList(e);
    }
  };

  const fetchCountryListAll = async () => {
    try {
      const { data } = await axios.get('/admin/countrylist');
      const formatCountryList = data?.data.map((country) => ({
        id: country._id,
        value: country._id,
        label: country.name,
        code: country.code
      }));
      formatCountryList.unshift({ id: 0, value: 0, label: 'All' });
      setCountryListAll(formatCountryList);
    } catch (e) {
      console.log(e);
      setErrorCountryList(e);
    }
  };

  const removeCountry = async (id) => {
    try {
      await axios.delete(`/admin/country/${id}`);
      success(t('actions.removeSuccess', { name: countryFields.country.label }));
      fetchCountries();
    } catch (e) {
      errorMsg(t('actions.removeFailed', { name: countryFields.country.label }));
      console.log(e);
    }
  };

  const handleLimitChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      pagesize: parseInt(event.target.value, 10),
      page: 0
    }));
  }, []);

  const handleOnPageChange = (event, newPage) => {
    setParams((pre) => ({
      ...pre,
      page: newPage
    }));
  };

  const onOpenModal = useCallback((isOpen) => {
    setOpen(isOpen);
  }, []);

  const onOpenModalCountry = useCallback((isOpen) => {
    setOpenModalCountry(isOpen);
    reset();
  }, []);

  useEffect(() => {
    fetchCountryList();
    fetchCountryListAll();
  }, []);

  useEffect(() => {
    fetchCountries();
  }, [params]);

  return {
    countries,
    setParams,
    params,
    error,
    handleOnPageChange,
    handleLimitChange,
    totalCountry,
    removeCountry,
    open,
    onOpenModal,
    onOpenModalCountry,
    openModalCountry,
    setValue,
    setIdCountry,
    idCountry,
    handleSubmit,
    isSubmitting,
    formItems,
    control,
    watch,
    onSubmit,
    countryList,
    errorCountryList,
    countryListAll
  };
};
