import axios from 'src/utils/axios';
import { useEffect, useState } from 'react';

export const useUserExport = () => {
  const [dataexport, setData] = useState([]);

  const fetchExport = async () => {
    try {
      const { data } = await axios.get(`/user/export`);
      setData(data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchExport();
  }, []);

  return {
    dataexport
  };
};
