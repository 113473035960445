import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import { useTranslation } from 'react-i18next';
import InputField from '../../components/FormControls/InputField';
import { fields } from '../../models/forgotpassword';
import { useForgotPassword } from '../../hooks/useForgotPassword';

const ForgotPasswordForm = (props) => {
  const { t } = useTranslation();
  const { control, formState, isSubmitting, onForgotPassword, handleSubmit } = useForgotPassword();
  return (
    <form noValidate {...props} onSubmit={handleSubmit(onForgotPassword)}>
      <InputField
        autoFocus
        fullWidth
        label={fields.username.label}
        name={fields.username.name}
        placeholder={fields.username.placeholder}
        inputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PhoneIphoneOutlinedIcon />
            </InputAdornment>
          )
        }}
        control={control}
        formState={formState}
      />
      <InputField
        required
        fullWidth
        label={fields.email.label}
        name={fields.email.name}
        placeholder={fields.email.placeholder}
        autoComplete="email"
        control={control}
        formState={formState}
      />
      <Box sx={{ mt: 2 }}>
        <Button color="primary" disabled={isSubmitting} fullWidth size="large" type="submit" variant="contained">
          {t('auth.login.forgotpassword')}
        </Button>
      </Box>
    </form>
  );
};

export default ForgotPasswordForm;
