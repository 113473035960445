import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputField from 'src/components/FormControls/InputField';
import NumberField from 'src/components/FormControls/NumberField';
import { programFields } from 'src/models/program';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import Checkbox from '@mui/material/Checkbox';
// import FormControlLabel from '@mui/material/FormControlLabel';
import SelectField from '../FormControls/SelectField';
import { PlaylistField } from './PlaylistField';
import SwitchField from '../FormControls/SwitchField';

export const ModalProgram = ({
  openModalProgram,
  onOpenModalProgram,
  onSubmit,
  handleSubmit,
  idProgram,
  userList,
  defaultPlaylist,
  videoList,
  dingList,
  register,
  watch,
  audioList,
  ...formItems
}) => {
  const { t } = useTranslation();
  const values = watch();
  // const bgmlist = [{ value: 1, label: 'BGM' }];
  return (
    <Dialog fullWidth maxWidth="md" open={openModalProgram} onClose={() => onOpenModalProgram(false)}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onOpenModalProgram(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {idProgram ? t('programs.action.updateProgram') : t('programs.action.addProgram')}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField
              {...formItems}
              required
              value={programFields.title.name}
              name={programFields.title.name}
              label={programFields.title.label}
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              required
              name={programFields.owner.name}
              label={programFields.owner.label}
              options={userList}
            />
          </Grid>
          <Grid item xs={6}>
            <SwitchField {...formItems} name={programFields.bgm.name} label={programFields.bgm.label} />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={programFields.audio.name}
              label={programFields.audio.label}
              options={audioList}
              disabled={!values.bgm}
              required={values.bgm}
            />
          </Grid>
          <Grid item xs={12}>
            <NumberField
              {...formItems}
              required
              name={programFields.totalProgramDuration.name}
              label={programFields.totalProgramDuration.label}
            />
          </Grid>
          <PlaylistField
            {...formItems}
            register={register}
            defaultPlaylist={defaultPlaylist}
            videoList={videoList}
            dingList={dingList}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOpenModalProgram(false)}>{t('programs.action.cancel')}</Button>
        <Button onClick={handleSubmit(onSubmit)}>
          {idProgram ? t('programs.action.save') : t('programs.action.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ModalProgram.propTypes = {
  idProgram: PropTypes.string,
  defaultPlaylist: PropTypes.object,
  openModalProgram: PropTypes.bool,
  onOpenModalProgram: PropTypes.func,
  watch: PropTypes.func,
  register: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  userList: PropTypes.array,
  videoList: PropTypes.array,
  dingList: PropTypes.array,
  audioList: PropTypes.array
};
