import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputField from 'src/components/FormControls/InputField';
import { schoolFields } from 'src/models/school';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useCountries } from 'src/hooks/user/useCountries';
import { useStates } from 'src/hooks/user/useStates';
import SelectField from 'src/components/FormControls/SelectField';

export const ModalSchool = ({
  openModalSchool,
  onOpenModalSchool,
  onSubmit,
  handleSubmit,
  watch,
  idSchool,
  typeOfSchoolList,
  ...formItems
}) => {
  const { t } = useTranslation();
  const values = watch();
  const { countryList } = useCountries();
  const { stateList } = useStates(values.country);

  return (
    <Dialog fullWidth maxWidth="md" open={openModalSchool} onClose={() => onOpenModalSchool(false)}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onOpenModalSchool(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {idSchool
              ? t('actions.update', { name: schoolFields.school.label })
              : t('actions.add', { name: schoolFields.school.label })}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={schoolFields.country.name}
              label={schoolFields.country.label}
              options={countryList}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={schoolFields.state.name}
              label={schoolFields.state.label}
              disabled={!values.country}
              options={stateList}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={schoolFields.name.name} label={schoolFields.name.label} required />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={schoolFields.code.name} label={schoolFields.code.label} required />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={schoolFields.typeOfSchool.name}
              label={schoolFields.typeOfSchool.label}
              options={typeOfSchoolList}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOpenModalSchool(false)}>
          {t('actions.cancel', { name: schoolFields.school.label })}
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>
          {idSchool
            ? t('actions.save', { name: schoolFields.school.label })
            : t('actions.create', { name: schoolFields.school.label })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ModalSchool.propTypes = {
  idSchool: PropTypes.string,
  openModalSchool: PropTypes.bool,
  onOpenModalSchool: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  watch: PropTypes.func,
  typeOfSchoolList: PropTypes.array
};
