import { useFieldArray } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { programFields } from 'src/models/program';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PlusIcon from 'src/icons/Plus';
import MinusIcon from 'src/icons/Minus';
import PropTypes from 'prop-types';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import SelectField from '../FormControls/SelectField';
import { TimingField } from './TimingField';

export const PlaylistField = ({ videoList, dingList, defaultPlaylist, register, ...formItems }) => {
  const { fields, append, remove } = useFieldArray({
    control: formItems.control,
    name: 'playlist'
  });

  return fields.map((field, index) => (
    <>
      <Grid item xs={5}>
        <SelectField
          {...formItems}
          required
          name={`playlist[${index}].item.video`}
          label={programFields.video.label}
          options={videoList}
        />
      </Grid>
      <Grid item xs={5}>
        <SelectField
          {...formItems}
          required
          name={`playlist[${index}].item.ding`}
          label={programFields.ding.label}
          options={dingList}
        />
      </Grid>
      <Grid item xs={2}>
        <Box sx={{ mt: 4 }}>
          <Tooltip title="Add">
            <IconButton size="small" aria-label="Add" onClick={() => append(defaultPlaylist)}>
              <PlusIcon />
            </IconButton>
          </Tooltip>
          {fields.length > 1 && (
            <Tooltip title="Remove">
              <IconButton size="small" aria-label="Remove" onClick={() => remove(index)}>
                <MinusIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TimingField {...formItems} index={index} />
        <Divider textAlign="left">
          <Chip color="primary" label="Add Video" />
        </Divider>
      </Grid>
    </>
  ));
};
PlaylistField.propTypes = {
  control: PropTypes.any
};
