import axios from 'src/utils/axios';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNotify } from '../useNotify';
import { FormSchema } from '../../models/schema';
import { stateFields } from '../../models/state';

const initialParams = {
  pagesize: 10,
  page: 0
};

export const useStates = (idCountry) => {
  const [states, setStates] = useState([]);
  const [totalState, setTotalState] = useState(0);
  const [idState, setIdState] = useState('');
  const [error, setError] = useState();
  const [params, setParams] = useState(initialParams);

  const [stateList, setStateList] = useState([]);
  const [errorStateList, setErrorStateList] = useState();
  const [open, setOpen] = useState(false);
  const [openModalState, setOpenModalState] = useState(false);
  const [stateListAll, setStateListAll] = useState([]);
  const { t } = useTranslation();
  const { success, error: errorMsg } = useNotify();

  const { schema } = useMemo(
    () => new FormSchema(stateFields.id, [stateFields.name, stateFields.code, stateFields.country]),
    []
  );

  const { handleSubmit, control, formState, watch, reset, setValue } = useForm({
    defaultValues: { [stateFields.country.name]: '', [stateFields.name.name]: '', [stateFields.code.name]: '' },
    resolver: schema
  });

  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const onSubmit = useCallback(async (formData) => {
    try {
      if (idState) {
        await axios.patch(`admin/state/${idState}`, formData);
        success(t('actions.updateSuccess', { name: stateFields.state.label }));
      } else {
        await axios.post('admin/state', formData);
        success(t('actions.createSuccess', { name: stateFields.state.label }));
      }
      reset();
      fetchStates();
      setOpenModalState(false);
    } catch (e) {
      console.log(e);
      if (idState) {
        errorMsg(t('actions.updateFailed', { name: stateFields.state.label }));
      } else {
        errorMsg(t('actions.createFailed', { name: stateFields.state.label }));
      }
    }
  });

  const fetchStates = async () => {
    try {
      const { data } = await axios.get(`/admin/state?pagesize=${params.pagesize}&page=${params.page + 1}`);
      setStates(data?.data?.states);
      setError({ success: data?.isSuccess, error: data?.msg });
      setTotalState(data?.data?.totalState);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const fetchStateList = async (id) => {
    try {
      const { data } = await axios.get(`/admin/statelist/${id}`);
      const formatStateList = data?.data.map((state) => ({ value: state._id, label: state.name, code: state.code }));
      setStateList(formatStateList);
    } catch (e) {
      console.log(e);
      setErrorStateList(e);
    }
  };

  const fetchStateListAll = async () => {
    try {
      const { data } = await axios.get(`/admin/statelist/0`);
      const formatStateList = data?.data.map((state) => ({
        id: state._id,
        value: state._id,
        label: state.name,
        code: state.code
      }));
      formatStateList.unshift({ id: 0, value: 0, label: 'All' });
      setStateListAll(formatStateList);
    } catch (e) {
      console.log(e);
      setErrorStateList(e);
    }
  };

  const removeState = async (id) => {
    try {
      await axios.delete(`/admin/state/${id}`);
      success(t('actions.removeSuccess', { name: stateFields.state.label }));
      fetchStates();
    } catch (e) {
      errorMsg(t('actions.removeFailed', { name: stateFields.state.label }));
      console.log(e);
    }
  };

  const handleLimitChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      pagesize: parseInt(event.target.value, 10),
      page: 0
    }));
  }, []);

  const handleOnPageChange = (event, newPage) => {
    setParams((pre) => ({
      ...pre,
      page: newPage
    }));
  };

  const onOpenModal = useCallback((isOpen) => {
    setOpen(isOpen);
  }, []);

  const onOpenModalState = useCallback((isOpen) => {
    setOpenModalState(isOpen);
    reset();
  }, []);

  useEffect(() => {
    if (idCountry) {
      fetchStateList(idCountry);
    }
  }, [idCountry]);

  useEffect(() => {
    fetchStates();
  }, [params]);

  useEffect(() => {
    fetchStateListAll();
  }, []);

  return {
    states,
    setParams,
    params,
    error,
    handleOnPageChange,
    handleLimitChange,
    totalState,
    removeState,
    open,
    onOpenModal,
    onOpenModalState,
    openModalState,
    setValue,
    setIdState,
    idState,
    handleSubmit,
    isSubmitting,
    formItems,
    control,
    watch,
    onSubmit,
    stateList,
    errorStateList,
    stateListAll
  };
};
