import { i18n } from '../i18n';
import StringField from './fields/stringField';
import IdField from './fields/idField';
import BooleanField from './fields/booleanField';

const label = (field) => i18n.t(`form.fields.${field}`);
const placeholder = (field) => i18n.t(`form.placeholder.${field}`);

export const programlogFields = {
  id: new IdField('id', 'id'),
  name: new StringField('name', label('name'), placeholder('name')),
  student: new StringField('student', label('student'), placeholder('student'), {
    required: true
  }),
  videos: new StringField('videos', label('videos'), placeholder('videos')),
  startProgram: new StringField('startProgram', label('startProgram'), placeholder('startProgram')),
  endProgram: new StringField('endProgram', label('endProgram'), placeholder('endProgram')),
  status: new StringField('status', label('status'), placeholder('status')),
  action: new StringField('action', label('action'), placeholder('action')),
  pdf: new StringField('pdf', label('pdf'), placeholder('pdf')),
  title: new StringField('title', label('title'), placeholder('title'), {
    required: true
  }),
  totalProgramDuration: new StringField(
    'totalProgramDuration',
    label('totalProgramDuration'),
    placeholder('total program duation'),
    {
      required: true
    }
  ),
  owner: new StringField('owner', label('owner'), placeholder('owner'), {
    required: true
  }),
  playlist: new StringField('playlist', label('playlist')),
  item: new StringField('item', label('item')),
  video: new StringField('video', label('video'), placeholder('video'), {
    required: true
  }),
  ding: new StringField('ding', label('ding'), placeholder('ding'), {
    required: true
  }),
  timing: new StringField('timing', label('timing')),
  seconds: new StringField('seconds', label('seconds'), placeholder('seconds'), {
    required: true
  }),
  flag: new StringField('flag', label('flag')),
  createdAt: new StringField('createdAt', label('createdAt')),
  bgm: new BooleanField('bgm', label('bgm'), placeholder('bgm'), {
    required: true
  }),
  audio: new StringField('audio', label('audio'), placeholder('audio'), {}),
  repeat: new StringField('repeat', label('repeat'), placeholder('repeat'), {}),
  school: new StringField('school', label('school'), placeholder('school'), {})
};

export default {
  programlogFields,
  placeholder
};
