import axios from 'src/utils/axios';
import { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNotify } from '../useNotify';
import { FormSchema } from '../../models/schema';
import { programFields } from '../../models/program';

const initialParams = {
  pagesize: 10,
  page: 0,
  studentname: '',
  schoolid: 0,
  startbegindate: new Date(),
  endbegindate: new Date(),
  enableStartDateFiltering: false
};
const defaultPlaylist = {
  [programFields.item.name]: {
    [programFields.video.name]: '',
    [programFields.ding.name]: '',
    [programFields.timing.name]: [
      { setup: '1', [programFields.seconds.name]: null },
      { setup: '2', [programFields.seconds.name]: null },
      { setup: '3', [programFields.seconds.name]: null },
      { setup: '4', [programFields.seconds.name]: null },
      { setup: '5', [programFields.seconds.name]: null },
      { setup: '6', [programFields.seconds.name]: null },
      { setup: '7', [programFields.seconds.name]: null },
      { setup: '8', [programFields.seconds.name]: null },
      { setup: '9', [programFields.seconds.name]: null },
      { setup: '10', [programFields.seconds.name]: null }
    ]
  }
};
export const usePrograms = () => {
  const [programs, setPrograms] = useState([]);
  const [totalPrograms, setTotalPrograms] = useState(0);
  const [idProgram, setIdProgram] = useState('');
  const [error, setError] = useState();
  const [params, setParams] = useState(initialParams);

  const [userList, setUserList] = useState([]);
  const [errorUserList, setErrorUserList] = useState();
  const [videoList, setVideoList] = useState([]);
  const [errorVideoList, setErrorVideoList] = useState();
  const [dingList, setDingList] = useState([]);
  const [errorDingList, setErrorDingList] = useState();
  const [audioList, setAudioList] = useState([]);

  const [open, setOpen] = useState(false);
  const [openModalProgram, setOpenModalProgram] = useState(false);

  const { t } = useTranslation();
  const { success, error: errorMsg } = useNotify();

  const { schema } = useMemo(
    () =>
      new FormSchema(programFields.id, [
        programFields.title,
        programFields.owner,
        programFields.flag,
        programFields.audio,
        programFields.bgm
      ]),
    []
  );

  const { handleSubmit, control, formState, watch, reset, setValue, register } = useForm({
    defaultValues: {
      [programFields.title.name]: '',
      [programFields.owner.name]: '',
      [programFields.playlist.name]: [defaultPlaylist],
      [programFields.flag.name]: 0,
      [programFields.audio.name]: null,
      [programFields.bgm.name]: false
    },
    resolver: schema
  });

  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const onSubmit = useCallback(async (formData) => {
    try {
      console.log(formData);
      if (idProgram) {
        await axios.patch(`admin/programnew/${idProgram}`, formData);
        success(t('programs.action.updateSuccess'));
      } else {
        await axios.post('admin/programnew', formData);
        success(t('programs.action.createSuccess'));
      }

      reset();
      fetchPrograms();
      setOpenModalProgram(false);
    } catch (e) {
      console.log(e);
      if (idProgram) {
        errorMsg(t('programs.action.updateFailed'));
      } else {
        errorMsg(t('programs.action.createFailed'));
      }
    }
  });

  const onSubmitBatch = useCallback(async (lstdata) => {
    try {
      console.log(lstdata);
      await axios.post('admin/programnew/batch', lstdata);
      success(t('programs.action.createSuccess'));

      reset();
      fetchPrograms();
    } catch (e) {
      console.log(e);
      if (idProgram) {
        errorMsg(t('programs.action.updateFailed'));
      } else {
        errorMsg(t('programs.action.createFailed'));
      }
    }
  });

  const fetchPrograms = async () => {
    try {
      let curbeginstartdate = 0;
      let curendstartdate = 0;
      if (!params.enableStartDateFiltering) {
        curbeginstartdate = 0;
        curendstartdate = 0;
      } else {
        // endbegindate.setDate(endbegindate.getDate() + 1);

        const tempenddate = new Date(params.endbegindate);
        tempenddate.setDate(tempenddate.getDate() + 1);
        curbeginstartdate = params.startbegindate.getTime().toFixed(0);
        curendstartdate = tempenddate.getTime().toFixed(0);
      }
      const { data } = await axios.get(
        `/admin/programnew?pagesize=${params.pagesize}&page=${params.page + 1}&studentname=${
          params.studentname
        }&school=${params.schoolid}&beginstartdate=${curbeginstartdate}&endstartdate=${curendstartdate}`
      );
      setPrograms(data?.data?.programs);
      setError({ success: data?.isSuccess, error: data?.msg });
      setTotalPrograms(data?.data?.totalProgram);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const fetchUserList = async () => {
    try {
      const { data } = await axios.get('/admin/user/name');
      const formatUser = data?.data.map((user) => ({ value: user._id, label: user.fullName }));
      setUserList(formatUser);
    } catch (e) {
      console.log(e);
      setErrorUserList(e);
    }
  };

  const fetchVideoList = async () => {
    try {
      const { data } = await axios.get('/admin/video');
      const formatVideo = data?.data.map((video) => ({ value: video._id, label: video.title }));
      setVideoList(formatVideo);
    } catch (e) {
      console.log(e);
      setErrorVideoList(e);
    }
  };

  const fetchDingList = async () => {
    try {
      const { data } = await axios.get('/admin/ding');
      const formatDing = data?.data.map((ding) => ({ value: ding._id, label: ding.name }));
      setDingList(formatDing);
    } catch (e) {
      console.log(e);
      setErrorDingList(e);
    }
  };

  const fetchAudioList = async () => {
    try {
      const { data } = await axios.get('/admin/audio');
      const formatDing = data?.data.map((ding) => ({ value: ding._id, id: ding._id, label: ding.title }));
      setAudioList(formatDing);
    } catch (e) {
      console.log(e);
      setErrorDingList(e);
    }
  };

  const removePrograms = async (id) => {
    try {
      await axios.delete(`/admin/programnew/${id}`);
      success(t('programs.action.removeSuccess'));
      fetchPrograms();
    } catch (e) {
      errorMsg(t('programs.action.removeFailed'));
      console.log(e);
    }
  };

  const handleLimitChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      pagesize: parseInt(event.target.value, 10),
      page: 0
    }));
  }, []);

  const handleOnPageChange = (event, newPage) => {
    setParams((pre) => ({
      ...pre,
      page: newPage
    }));
  };

  const handleSearchChange = (event) => {
    setParams((pre) => ({
      ...pre,
      studentname: event.target.value,
      page: 0
    }));
  };

  const handleSchoolChange = (event) => {
    setParams((pre) => ({
      ...pre,
      schoolid: event.target.value,
      page: 0
    }));
  };

  const handleBeginStartDay = (event) => {
    console.log(event);
    setParams((pre) => ({
      ...pre,
      startbegindate: event,
      page: 0
    }));
  };

  const handleEndStartDay = (event) => {
    setParams((pre) => ({
      ...pre,
      endbegindate: event,
      page: 0
    }));
  };

  const handleStartDateFiltering = (event) => {
    console.log(event);
    setParams((pre) => ({
      ...pre,
      enableStartDateFiltering: event.target.checked,
      page: 0
    }));
  };

  const onOpenModal = useCallback((isOpen) => {
    setOpen(isOpen);
  }, []);

  const onOpenModalProgram = useCallback((isOpen) => {
    setOpenModalProgram(isOpen);
    reset();
  }, []);

  useEffect(() => {
    fetchPrograms();
  }, [params]);

  useEffect(() => {
    fetchUserList();
    fetchVideoList();
    fetchDingList();
    fetchAudioList();
  }, []);

  return {
    programs,
    setParams,
    params,
    error,
    handleOnPageChange,
    handleLimitChange,
    totalPrograms,
    removePrograms,
    open,
    onOpenModal,
    onOpenModalProgram,
    openModalProgram,
    setValue,
    setIdProgram,
    idProgram,
    handleSubmit,
    isSubmitting,
    formItems,
    control,
    userList,
    errorUserList,
    videoList,
    errorVideoList,
    dingList,
    errorDingList,
    defaultPlaylist,
    watch,
    onSubmit,
    register,
    audioList,
    handleSearchChange,
    handleSchoolChange,
    handleBeginStartDay,
    handleEndStartDay,
    handleStartDateFiltering,
    onSubmitBatch
  };
};
