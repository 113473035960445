import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import XlsxPopulate from 'xlsx-populate';
import { useNotify } from 'src/hooks/useNotify';
import PlusIcon from '../../icons/Plus';

export const ImportUser = ({ countryListAll, stateListAll, trainingReasonList, schoolListAll, onSubmitBatch }) => {
  //   const { t } = useTranslation();
  const { error } = useNotify();
  // region export template excel

  function getWorkbook() {
    return new Promise((resolve) => {
      const req = new XMLHttpRequest();
      req.open('GET', '/static/student_template.xlsx', true);
      req.responseType = 'arraybuffer';
      req.onreadystatechange = function a() {
        if (req.readyState === 4 && req.status === 200) {
          resolve(XlsxPopulate.fromDataAsync(req.response));
          // .then(function (workbook) {
          //     // ...
          // });
        }
      };

      req.send();
    });
  }
  // fetch(`/static/student_template.xlsx`).then((r) => XlsxPopulate.fromDataAsync(r));
  // return new Promise((resolve) => {
  //   fetch(`static/student_template.xlsx`).then((r) => resolve(XlsxPopulate.fromDataAsync(r)));
  //   // const req = new XMLHttpRequest();
  //   // const url = `static/student_template.xlsx`;
  //   // req.open('GET', url, true);
  //   // req.responseType = 'arraybuffer';
  //   // req.onreadystatechange = function (e) {
  //   //   if (req.readyState === 4) {
  //   //     if (req.status === 200) {
  //   //       resolve(XlsxPopulate.fromDataAsync(req.response));
  //   //     } else {
  //   //       reject(new Error(`Received a ${req.status} HTTP code. ${e}`));
  //   //     }
  //   //   }
  //   // };
  //   // req.send();
  // });
  // }

  function generate() {
    return (
      getWorkbook()
        // .then((r) => XlsxPopulate.fromDataAsync(r))
        .then((workbook) => {
          const sheet1 = workbook.sheet(1);
          //  console.log(countryListAll);
          // console.log(countryListAll.map((row) => row.label));
          // console.log(trainingReasonList);
          let celldata = trainingReasonList.map((row) => [row.label]);
          sheet1.range(`B1:B2`).value(celldata);
          celldata = countryListAll.filter((row) => row.value !== 0).map((row) => [row.label]);
          sheet1.range(`C1:C${celldata.length}`).value(celldata);

          celldata = stateListAll.filter((row) => row.value !== 0).map((row) => [row.label]);
          sheet1.range(`D1:D${celldata.length}`).value(celldata);

          celldata = schoolListAll.filter((row) => row.value !== 0).map((row) => [row.label]);
          sheet1.range(`E1:E${celldata.length}`).value(celldata);
          return workbook.outputAsync();
        })
    );
  }

  async function exportUserTemplate() {
    return generate()
      .then((blob) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, 'template.xlsx');
        } else {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = url;
          a.download = 'out.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      })
      .catch((err) => {
        error(err.message || err);
        throw err;
      });
  }
  async function onFileChange(event) {
    // Update the state
    console.log('call onFileChange');
    const file = event.target.files[0];
    if (!file) return error('You must select a file.');
    return XlsxPopulate.fromDataAsync(file).then((workbook) => {
      // workbook.sheet(0).cell('A1').value('This was created in the browser!').style('fontColor', 'ff0000');
      //  const totalrows = workbook.sheet(0).cell(`M1`).value();

      // Get 2D array of all values in the worksheet.
      const values = workbook.sheet(0).usedRange().value();
      //  console.log(schoolListAll);

      const lstData = [];
      console.log(countryListAll);
      for (let index = 0; index < values.length; index++) {
        const element = values[index];
        console.log(element);
        if (index > 0) {
          const role = '6189f52c4b2522925f63291e';
          const fullName = element[0];
          const gender = element[1];
          const joinDate = new Date((parseInt(element[2], 10) - 25569) * 24 * 60 * 60 * 1000);
          const birthday = new Date((parseInt(element[3], 10) - 25569) * 24 * 60 * 60 * 1000);
          const trainingId = element[4];
          const password = element[5];
          const trainingReason = trainingReasonList.find((elem) => element[6] === elem.label);
          const country = countryListAll.find((elem) => elem.label === element[7]);
          const state = stateListAll.find((elem) => elem.label === element[8]);
          const school = schoolListAll.find((elem) => elem.label === element[9]);
          const remarks = element[10];
          const note = element[11];

          if (!trainingId) {
            error(`trainid in row ${index + 1} invalid`);
            return;
          }
          if (!trainingReason) {
            error(`trainreason in row ${index + 1} invalid`);
            return;
          }

          if (!country) {
            console.log(element[7]);
            error(`country in row ${index + 1} invalid`);
            return;
          }

          if (!state) {
            error(`state in row ${index + 1} invalid`);
            return;
          }

          if (!school) {
            error(`school in row ${index + 1} invalid`);
            return;
          }

          const userName = `${country.code}-${state.code}-${school.code}-${trainingId}`;

          const formatData = {
            username: userName,
            role,
            fullName,
            gender,
            joinDate,
            birthday,
            trainingId,
            password,
            trainingReason: trainingReason.id,
            country: country.id,
            state: state.id,
            school: school.id,
            remarks,
            note,
            status: 1
          };

          lstData.push(formatData);
        }
      }

      if (lstData.length > 0) {
        onSubmitBatch(lstData);
      } else {
        error(`Nothing to import`);
      }
    });
  }
  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<PlusIcon fontSize="small" />}
        onClick={() => {
          exportUserTemplate();
        }}
      >
        Download template
      </Button>
      <label htmlFor="contained-button-file">
        <input id="contained-button-file" type="file" onChange={onFileChange} style={{ display: 'none' }} />
        <Button variant="contained" component="span">
          Upload
        </Button>
      </label>
    </>
  );
};
ImportUser.propTypes = {
  countryListAll: PropTypes.array,
  stateListAll: PropTypes.array,
  trainingReasonList: PropTypes.array,
  schoolListAll: PropTypes.array,
  onSubmitBatch: PropTypes.func
};
