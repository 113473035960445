import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import InputField from 'src/components/FormControls/InputField';
import SelectField from 'src/components/FormControls/SelectField';
import { stateFields } from 'src/models/state';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useCountries } from 'src/hooks/user/useCountries';

export const ModalState = ({ openModalState, onOpenModalState, onSubmit, handleSubmit, idState, ...formItems }) => {
  const { t } = useTranslation();
  const { countryList } = useCountries();
  return (
    <Dialog fullWidth maxWidth="md" open={openModalState} onClose={() => onOpenModalState(false)}>
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => onOpenModalState(false)} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {idState
              ? t('actions.update', { name: stateFields.state.label })
              : t('actions.add', { name: stateFields.state.label })}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <InputField {...formItems} name={stateFields.name.name} label={stateFields.name.label} required />
          </Grid>
          <Grid item xs={6}>
            <InputField {...formItems} name={stateFields.code.name} label={stateFields.code.label} required />
          </Grid>
          <Grid item xs={6}>
            <SelectField
              {...formItems}
              name={stateFields.country.name}
              label={stateFields.country.label}
              options={countryList}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onOpenModalState(false)}>
          {t('actions.cancel', { name: stateFields.state.label })}
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>
          {idState
            ? t('actions.save', { name: stateFields.state.label })
            : t('actions.create', { name: stateFields.state.label })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
ModalState.propTypes = {
  idState: PropTypes.string,
  openModalState: PropTypes.bool,
  roles: PropTypes.array,
  watch: PropTypes.func,
  onOpenModalState: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func
};
