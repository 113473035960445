import axios from 'src/utils/axios';
import { useEffect, useState, useCallback } from 'react';

import { programlogFields } from '../../models/programlog';

const initialParams = {
  pagesize: 10,
  page: 0,
  studentname: '',
  schoolid: 0,
  startbegindate: new Date(),
  endbegindate: new Date(),
  enableStartDateFiltering: false
};
const defaultPlaylist = {
  [programlogFields.item.name]: {
    [programlogFields.video.name]: '',
    [programlogFields.ding.name]: '',
    [programlogFields.timing.name]: [
      { setup: '1', [programlogFields.seconds.name]: null },
      { setup: '2', [programlogFields.seconds.name]: null },
      { setup: '3', [programlogFields.seconds.name]: null },
      { setup: '4', [programlogFields.seconds.name]: null },
      { setup: '5', [programlogFields.seconds.name]: null },
      { setup: '6', [programlogFields.seconds.name]: null },
      { setup: '7', [programlogFields.seconds.name]: null },
      { setup: '8', [programlogFields.seconds.name]: null },
      { setup: '9', [programlogFields.seconds.name]: null },
      { setup: '10', [programlogFields.seconds.name]: null }
    ]
  }
};
export const useProgramLogs = () => {
  const [programs, setPrograms] = useState([]);
  const [totalPrograms, setTotalPrograms] = useState(0);

  const [params, setParams] = useState(initialParams);

  // const [userList, setUserList] = useState([]);
  // const [errorUserList, setErrorUserList] = useState();
  // const [videoList, setVideoList] = useState([]);
  // const [errorVideoList, setErrorVideoList] = useState();
  // const [dingList, setDingList] = useState([]);
  // const [errorDingList, setErrorDingList] = useState();
  // const [audioList, setAudioList] = useState([]);
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const [openModalProgram, setOpenModalProgram] = useState(false);

  // const { t } = useTranslation();
  // const { success, error: errorMsg } = useNotify();

  const fetchPrograms = async () => {
    try {
      let curbeginstartdate = 0;
      let curendstartdate = 0;
      if (!params.enableStartDateFiltering) {
        curbeginstartdate = 0;
        curendstartdate = 0;
      } else {
        // endbegindate.setDate(endbegindate.getDate() + 1);

        const tempenddate = new Date(params.endbegindate);
        tempenddate.setDate(tempenddate.getDate() + 1);
        curbeginstartdate = params.startbegindate.getTime().toFixed(0);
        curendstartdate = tempenddate.getTime().toFixed(0);
      }
      const { data } = await axios.get(
        `/admin/programnew/logs?pagesize=${params.pagesize}&page=${params.page + 1}&studentname=${
          params.studentname
        }&school=${params.schoolid}&beginstartdate=${curbeginstartdate}&endstartdate=${curendstartdate}`
      );
      setPrograms(data?.data?.programs);
      setError({ success: data?.isSuccess, error: data?.msg });
      setTotalPrograms(data?.data?.totalProgram);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const handleLimitChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      pagesize: parseInt(event.target.value, 10),
      page: 0
    }));
  }, []);

  const handleOnPageChange = (event, newPage) => {
    setParams((pre) => ({
      ...pre,
      page: newPage
    }));
  };

  const handleSearchChange = (event) => {
    setParams((pre) => ({
      ...pre,
      studentname: event.target.value,
      page: 0
    }));
  };

  const handleSchoolChange = (event) => {
    setParams((pre) => ({
      ...pre,
      schoolid: event.target.value,
      page: 0
    }));
  };

  const handleBeginStartDay = (event) => {
    console.log(event);
    setParams((pre) => ({
      ...pre,
      startbegindate: event,
      page: 0
    }));
  };

  const handleEndStartDay = (event) => {
    setParams((pre) => ({
      ...pre,
      endbegindate: event,
      page: 0
    }));
  };

  const handleStartDateFiltering = (event) => {
    console.log(event);
    setParams((pre) => ({
      ...pre,
      enableStartDateFiltering: event.target.checked,
      page: 0
    }));
  };

  const onOpenModal = useCallback((isOpen) => {
    setOpen(isOpen);
  }, []);

  const onOpenModalProgram = useCallback((isOpen) => {
    setOpenModalProgram(isOpen);
    // reset();
  }, []);

  useEffect(() => {
    fetchPrograms();
  }, [params]);

  return {
    programs,
    setParams,
    params,
    error,
    handleOnPageChange,
    handleLimitChange,
    totalPrograms,
    open,
    onOpenModal,
    onOpenModalProgram,
    openModalProgram,
    defaultPlaylist,
    handleSearchChange,
    handleSchoolChange,
    handleBeginStartDay,
    handleEndStartDay,
    handleStartDateFiltering
  };
};
