import { Grid, Box, Card, FormControl, Checkbox } from '@mui/material';
import { useTrainingSignals } from 'src/hooks/useTrainingSignals';
import TrainingSignalList from 'src/components/trainingSignals/list';

// import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';
// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

export const TrainingSignalsContainer = () => {
  const {
    trainingSignals,
    params,
    handleOnPageChange,
    handleLimitChange,
    totalTraingingSignals,
    setValue,
    handleSearchChange,
    handleBeginStartDay,
    handleEndStartDay,
    handleStartDateFiltering
  } = useTrainingSignals();

  // const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-search"
            onChange={handleSearchChange}
            value={params.studentname}
            variant="outlined"
            fullWidth
            label="Search student name"
            type="search"
          />
        </Grid>

        <Grid item xs={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction="row" spacing={2}>
                <Checkbox checked={params.enableStartDateFiltering} onChange={handleStartDateFiltering} />
                <DatePicker
                  disabled={!params.enableStartDateFiltering}
                  label="Begin start date"
                  inputFormat="dd/MM/yyyy"
                  value={params.startbegindate}
                  onChange={handleBeginStartDay}
                  renderInput={(params1) => <TextField {...params1} />}
                />
                <DatePicker
                  disabled={!params.enableStartDateFiltering}
                  label="End start date"
                  inputFormat="dd/MM/yyyy"
                  value={params.endbegindate}
                  onChange={handleEndStartDay}
                  renderInput={(params2) => <TextField {...params2} />}
                />
              </Stack>
            </LocalizationProvider>
          </FormControl>
        </Grid>
      </Grid>
      <Card>
        <TrainingSignalList
          programs={trainingSignals}
          setValue={setValue}
          totalTraingingSignals={totalTraingingSignals}
          handleOnPageChange={handleOnPageChange}
          handleLimitChange={handleLimitChange}
          {...params}
        />
      </Card>
    </Box>
  );
};
