import { Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { videoFields } from 'src/models/video';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
// import EditIcon from '@mui/icons-material/Edit';

const VideoList = ({ videos, removeVideos, open, onOpenModal, idVideo }) => {
  const { t } = useTranslation();

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{videoFields.title.label}</TableCell>

            <TableCell>{videoFields.status.label}</TableCell>
            {/* <TableCell>{videoFields.action.label}</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {videos &&
            videos.map((program) => (
              <TableRow key={program._id}>
                <TableCell>{program.title}</TableCell>
                <TableCell>{program.title && t(`videos.status.${program.status}`)}</TableCell>
                {/* <TableCell>
                  {program.title && (
                    <>
                      <Tooltip title="Edit">
                        <IconButton
                          size="small"
                          aria-label="edit"
                          onClick={() => {
                            onOpenModalVideo(true);
                            setIdVideo(program._id);
                            setValue(videoFields.title.name, program.title);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          size="small"
                          aria-label="delete"
                          onClick={() => {
                            onOpenModal(true);
                            setIdVideo(program._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </TableCell> */}
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog
        open={open}
        onClose={() => onOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('dialog.title')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => onOpenModal(false)}> {t('dialog.noButton')}</Button>
          <Button
            onClick={() => {
              removeVideos(idVideo);
              onOpenModal(false);
            }}
            autoFocus
          >
            {t('dialog.yesButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
VideoList.propTypes = {
  videos: PropTypes.array,
  open: PropTypes.bool,
  onOpenModal: PropTypes.func,
  //   onOpenModalVideo: PropTypes.func,
  idVideo: PropTypes.string,
  //   setIdVideo: PropTypes.func,
  removeVideos: PropTypes.func
  //   setValue: PropTypes.func
};
export default VideoList;
