import { FormControl, FormLabel, Select, MenuItem, Grid, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import _get from 'lodash/get';

const SelectField = ({
  name,
  control,
  formState,
  error,
  label,
  inputProps,
  helperText,
  options,
  labelProps,
  layout,
  disabled,
  multiple,
  ...props
}) => {
  const { errors = {} } = formState;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl
          fullWidth
          {...props}
          disabled={disabled}
          error={error || !!_get(errors, `${name}.message`)}
          helperText={helperText || _get(errors, `${name}.message`)}
        >
          <Grid container {...layout.grid}>
            <Grid item {...layout.label}>
              {!!label && <FormLabel {...labelProps}>{label}</FormLabel>}
            </Grid>
            <Grid item {...layout.input}>
              <Select
                onChange={(e) => {
                  console.log('value changed:', e.target.value);
                }}
                displayEmpty
                fullWidth
                size="small"
                {...field}
                multiple={multiple}
                disabled={disabled}
                value={multiple && !Array.isArray(field.value) ? [field.value] : field.value}
                error={error || !!_get(errors, `${name}.message`)}
                helperText={helperText || _get(errors, `${name}.message`)}
              >
                {options &&
                  options.map((item) => (
                    <MenuItem value={item.value} key={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item {...layout.label} />
                <Grid item {...layout.input}>
                  <FormHelperText sx={{ mx: 0 }}>{helperText || _get(errors, `${name}.message`)}</FormHelperText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </FormControl>
      )}
    />
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  formState: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,

  error: PropTypes.bool,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,

  inputProps: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  margin: PropTypes.string,
  variant: PropTypes.string,
  helperText: PropTypes.string,
  autoComplete: PropTypes.string,
  labelProps: PropTypes.object,
  layout: PropTypes.object,

  valueField: PropTypes.string,
  labelField: PropTypes.string
};

SelectField.defaultProps = {
  error: false,
  required: false,
  autoFocus: false,
  disabled: false,
  multiple: false,
  inputProps: null,
  type: '',
  label: '',
  helperText: '',
  autoComplete: '',
  valueField: 'id',
  labelField: 'label',
  layout: {
    grid: {
      my: 1
    },
    label: {
      xs: 12
    },
    input: {
      xs: 12
    }
  }
};

export default SelectField;
