import axios from 'src/utils/axios';
import { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useNotify } from '../useNotify';

const initialParams = {
  pagesize: 10,
  page: 0,
  studentname: ''
};

export const useConfigs = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [params, setParams] = useState(initialParams);

  //   const { success, error: errorMsg } = useNotify();

  const fetchData = async () => {
    try {
      const { resData } = await axios.get(`/admin/config`);

      setData(resData?.data?.programs);
      setError({ success: resData?.isSuccess, error: resData?.msg });
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  return {
    data,
    setParams,
    params,
    error
  };
};
