import { Grid, Box, Card, FormControl, Select, MenuItem, InputLabel, Checkbox } from '@mui/material';
import { usePrograms } from 'src/hooks/programs/usePrograms';
import { useSchools } from 'src/hooks/user/useSchools';
import ProgramList from 'src/components/programs/ProgramList';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ModalProgram } from 'src/components/programs/ModalProgram';
import TextField from '@mui/material/TextField';
// import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { ImportProgram } from 'src/components/programs/ImportProgram';
import PlusIcon from '../../icons/Plus';

export const ProgramsContainer = () => {
  const {
    programs,
    params,
    handleOnPageChange,
    handleLimitChange,
    totalPrograms,
    removePrograms,
    open,
    onOpenModal,
    idProgram,
    setIdProgram,
    onSubmit,
    handleSubmit,
    formItems,
    openModalProgram,
    onOpenModalProgram,
    setValue,
    userList,
    videoList,
    dingList,
    defaultPlaylist,
    register,
    watch,
    audioList,
    handleSearchChange,
    handleSchoolChange,
    handleBeginStartDay,
    handleEndStartDay,
    handleStartDateFiltering,
    onSubmitBatch
  } = usePrograms();

  const { schoolListAll } = useSchools();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-search"
            onChange={handleSearchChange}
            value={params.studentname}
            variant="outlined"
            fullWidth
            label="Search student name"
            type="search"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="school-select-label">School</InputLabel>
            <Select
              labelId="school-select-labell"
              id="school-select"
              value={params.schoolid}
              label="School"
              onChange={handleSchoolChange}
            >
              {schoolListAll &&
                schoolListAll.map((item) => (
                  <MenuItem value={item.value} key={`school.${item.value}`}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack direction="row" spacing={2}>
                <Checkbox checked={params.enableStartDateFiltering} onChange={handleStartDateFiltering} />
                <DatePicker
                  disabled={!params.enableStartDateFiltering}
                  label="Begin start date"
                  inputFormat="dd/MM/yyyy"
                  value={params.startbegindate}
                  onChange={handleBeginStartDay}
                  renderInput={(params1) => <TextField {...params1} />}
                />
                <DatePicker
                  disabled={!params.enableStartDateFiltering}
                  label="End start date"
                  inputFormat="dd/MM/yyyy"
                  value={params.endbegindate}
                  onChange={handleEndStartDay}
                  renderInput={(params2) => <TextField {...params2} />}
                />
              </Stack>
            </LocalizationProvider>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<PlusIcon fontSize="small" />}
            onClick={() => {
              onOpenModalProgram(true);
              setIdProgram(null);
            }}
          >
            {t('programs.action.addProgram')}
          </Button>
        </Grid>
        <Grid>
          <ImportProgram
            audioList={audioList}
            videoList={videoList}
            dingList={dingList}
            userList={userList}
            onSubmitBatch={onSubmitBatch}
          />
        </Grid>
      </Grid>
      <Card>
        <ProgramList
          programs={programs}
          setValue={setValue}
          totalPrograms={totalPrograms}
          removePrograms={removePrograms}
          idProgram={idProgram}
          setIdProgram={setIdProgram}
          open={open}
          onOpenModal={onOpenModal}
          handleOnPageChange={handleOnPageChange}
          handleLimitChange={handleLimitChange}
          openModalProgram={openModalProgram}
          onOpenModalProgram={onOpenModalProgram}
          {...params}
        />
      </Card>
      <ModalProgram
        watch={watch}
        register={register}
        defaultPlaylist={defaultPlaylist}
        onSubmit={onSubmit}
        idProgram={idProgram}
        handleSubmit={handleSubmit}
        userList={userList}
        videoList={videoList}
        dingList={dingList}
        {...formItems}
        openModalProgram={openModalProgram}
        onOpenModalProgram={onOpenModalProgram}
        audioList={audioList}
      />
    </Box>
  );
};
