import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { TABS } from 'src/constants/tabs';
import { UsersContainer } from 'src/containers/users';
import { CountriesContainer } from 'src/containers/countries';
import { StatesContainer } from 'src/containers/states';
import { SchoolsContainer } from 'src/containers/schools';
import { TrainingReasonsContainer } from 'src/containers/trainingReasons';

export const DashboardContainer = () => {
  const { t } = useTranslation();
  const [value, setValue] = useState('0');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const tabPanelContainer = [
    <UsersContainer />,
    <CountriesContainer />,
    <StatesContainer />,
    <SchoolsContainer />,
    <TrainingReasonsContainer />
  ];

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} sx={{ backgroundColor: 'white' }}>
            {TABS.map((item) => (
              <Tab key={item.value} label={t(`users.tabs.${item.label}`)} value={item.value} />
            ))}
          </TabList>
        </Box>
        {tabPanelContainer.map((item, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TabPanel key={index} value={`${index}`}>
            {item}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
};
