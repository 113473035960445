import { Grid, Box, Card } from '@mui/material';
import { useUsers } from 'src/hooks/user/useUsers';
import UserList from 'src/components/users/UserList';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
// import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';

import { useTranslation } from 'react-i18next';
import { ModalUser } from 'src/components/users/ModalUser';
import { useSchools } from 'src/hooks/user/useSchools';
import { useCountries } from 'src/hooks/user/useCountries';
import { useStates } from 'src/hooks/user/useStates';
import { useUserExport } from 'src/hooks/user/useUserExport';
import { saveAs } from 'file-saver';
import XlsxPopulate from 'xlsx-populate';
import { useTrainingReasons } from 'src/hooks/user/useTrainingReasons';
import { ImportUser } from 'src/components/users/ImportUser';
import PlusIcon from '../../icons/Plus';
// import DownloadIcon from '../../icons/Plus';
import { fields } from '../../models/user';

export const UsersContainer = () => {
  const {
    users,
    params,
    handleOnPageChange,
    handleLimitChange,
    handleSearchChange,
    totalUsers,
    removeUsers,
    open,
    onOpenModal,
    roles,
    idUser,
    setIdUser,
    onSubmit,
    handleSubmit,
    formItems,
    openModalUser,
    onOpenModalUser,
    setValue,
    watch,
    setUserName,
    handleFilterChange,
    onSubmitBatch
  } = useUsers();

  const { t } = useTranslation();
  // const values = watch();
  const { countryListAll } = useCountries();
  const { stateListAll } = useStates(0);
  const { trainingReasonList } = useTrainingReasons('');

  const { schoolListAll } = useSchools(0);
  const { dataexport } = useUserExport(0);

  // console.log(stateListAll);
  // const countryCode = countryList.filter((country) => country.value === params.contryid)[0]?.code;
  // const stateCode = stateList.filter((state) => state.value === params.stateid)[0]?.code;
  // const schoolCode = schoolList.filter((school) => school.value === params.schoolid)[0]?.code;

  // const valueStudentID = `${countryCode || ''}${stateCode ? `-${stateCode}` : ''}${schoolCode ? `-${schoolCode}` : ''}`;

  // useEffect(() => {
  //   if (valueStudentID) {
  //     setUserName(valueStudentID);
  //   }
  // }, [valueStudentID]);

  // const inputHandler = (e) => {
  //   const lowerCase = e.target.value;
  //   params.studentname = lowerCase;
  //   params.page = 0;
  //   setValue(lowerCase);
  //   handleOnPageChange();
  // };
  function getSheetData(data, header) {
    const fieldss = Object.keys(data[0]);
    const sheetData = data.map((row) => fieldss.map((fieldName) => (row[fieldName] ? row[fieldName] : '')));
    sheetData.unshift(header);
    return sheetData;
  }
  async function saveAsExcel() {
    const data = dataexport;
    const header = [
      'no',
      'status',
      'Training ID',
      'Name',
      'Birthday',
      'gender',
      'height',
      'weight',
      'country',
      'state',
      'school',
      'Joining date',
      'training reason',
      'Attendance',
      'Remarks'
    ];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(data, header);
      const totalColumns = sheetData[0].length;
      const rangeHeader = workbook.sheet(0).range('A1:O1');
      rangeHeader.value('quingdaoTM MemberList');
      rangeHeader.style({ horizontalAlignment: 'center', verticalAlignment: 'center', bold: true });
      rangeHeader.merged(true);
      sheet1.cell('B2').value(`PrintDate : ${new Date().toLocaleString()}`);
      sheet1.cell('A3').value(sheetData);
      const range = sheet1.range(`A3:O${data.length + 3}`);
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(3).style('bold', true);
      sheet1.range(`A3:${endColumn}3`).style('fill', 'A4CBff');
      range.style('border', true);
      return workbook.outputAsync().then((res) => {
        saveAs(res, 'export_data.xlsx');
      });
    });
  }

  // async function exportUserTemplate() {
  //   XlsxPopulate.fromFileAsync(`../public/static/student_template.xlsx`).then(async (workbook) => {
  //     const sheet1 = workbook.sheet(1);

  //     sheet1.cell('C1').value(countryListAll.map((row) => row.name));
  //     return workbook.outputAsync().then((res) => {
  //       saveAs(res, 'template.xlsx');
  //     });
  //   });
  // }
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Grid item xs={3}>
          <TextField
            id="outlined-search"
            onChange={handleSearchChange}
            value={params.studentname}
            variant="outlined"
            fullWidth
            label="Search"
            type="search"
          />
        </Grid>
        <Grid item xs={3}>
          <Autocomplete
            id="cbo_country"
            disablePortal
            onChange={handleFilterChange}
            options={countryListAll}
            name="cbo_country"
            renderInput={(params1) => (
              <TextField {...params1} label={fields.country.label} name={fields.country.name} />
            )}
          />
        </Grid>

        <Grid item xs={3}>
          <Autocomplete
            id="cbo_state"
            disablePortal
            name="cbo_state"
            // {...countryListAll}
            onChange={handleFilterChange}
            options={stateListAll}
            renderInput={(params1) => <TextField {...params1} label={fields.state.label} name={fields.state.name} />}
          />
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id="cbo_school"
            name="cbo_school"
            // {...countryListAll}
            disablePortal
            onChange={handleFilterChange}
            options={schoolListAll}
            renderInput={(params1) => <TextField {...params1} label={fields.school.label} name={fields.school.name} />}
          />
        </Grid>

        <Button
          xs={2}
          variant="outlined"
          color="primary"
          onClick={() => {
            saveAsExcel();
          }}
          // startIcon={<PlusIcon fontSize="small" />}
        >
          {t('users.action.export')}
        </Button>

        <Button
          variant="outlined"
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          onClick={() => {
            onOpenModalUser(true);
            setIdUser(null);
          }}
        >
          {t('users.action.addUser')}
        </Button>
        <ImportUser
          countryListAll={countryListAll}
          stateListAll={stateListAll}
          schoolListAll={schoolListAll}
          trainingReasonList={trainingReasonList}
          onSubmitBatch={onSubmitBatch}
        />
      </Grid>
      <Card>
        <UserList
          users={users}
          setValue={setValue}
          totalUsers={totalUsers}
          removeUsers={removeUsers}
          idUser={idUser}
          setIdUser={setIdUser}
          open={open}
          onOpenModal={onOpenModal}
          handleOnPageChange={handleOnPageChange}
          handleLimitChange={handleLimitChange}
          openModalUser={openModalUser}
          onOpenModalUser={onOpenModalUser}
          {...params}
        />
      </Card>
      <ModalUser
        roles={roles}
        setUserName={setUserName}
        onSubmit={onSubmit}
        idUser={idUser}
        handleSubmit={handleSubmit}
        watch={watch}
        {...formItems}
        openModalUser={openModalUser}
        onOpenModalUser={onOpenModalUser}
      />
    </Box>
  );
};
