import { Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow } from '@mui/material';
import { schoolFields } from 'src/models/school';
import { stateFields } from 'src/models/state';
import { countryFields } from 'src/models/country';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useState } from 'react';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const SchoolList = ({
  schools,
  page,
  pagesize,
  handleOnPageChange,
  handleLimitChange,
  totalSchool,
  removeSchool,
  open,
  onOpenModal,
  idSchool,
  setIdSchool,
  onOpenModalSchool,
  setValue
}) => {
  const { t } = useTranslation();
  const [oderDirection, setOrderDirection] = useState('asc');
  const [valueToOderBy, setValueToOderBy] = useState(schoolFields.name.label);

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOderBy === property && oderDirection === 'asc';
    setValueToOderBy(property);
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === countryFields.name.name}
                direction={valueToOderBy === countryFields.name.name ? oderDirection : 'asc'}
                onClick={createSortHandler(countryFields.name.name)}
              >
                {countryFields.name.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === countryFields.code.name}
                direction={valueToOderBy === countryFields.code.name ? oderDirection : 'asc'}
                onClick={createSortHandler(countryFields.code.name)}
              >
                {countryFields.code.label}
              </TableSortLabel>
            </TableCell>{' '}
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === stateFields.name.name}
                direction={valueToOderBy === stateFields.name.name ? oderDirection : 'asc'}
                onClick={createSortHandler(stateFields.name.name)}
              >
                {stateFields.name.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === stateFields.code.name}
                direction={valueToOderBy === stateFields.code.name ? oderDirection : 'asc'}
                onClick={createSortHandler(stateFields.code.name)}
              >
                {stateFields.code.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === schoolFields.name.name}
                direction={valueToOderBy === schoolFields.name.name ? oderDirection : 'asc'}
                onClick={createSortHandler(schoolFields.name.name)}
              >
                {schoolFields.name.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === schoolFields.code.name}
                direction={valueToOderBy === schoolFields.code.name ? oderDirection : 'asc'}
                onClick={createSortHandler(schoolFields.code.name)}
              >
                {schoolFields.code.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === schoolFields.typeOfSchool.name}
                direction={valueToOderBy === schoolFields.typeOfSchool.name ? oderDirection : 'asc'}
                onClick={createSortHandler(schoolFields.typeOfSchool.name)}
              >
                {schoolFields.typeOfSchool.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>{schoolFields.action.label}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schools &&
            stableSort(schools, getComparator(oderDirection, valueToOderBy)).map((school) => (
              <TableRow key={school._id}>
                <TableCell>{school?.country?.name}</TableCell>
                <TableCell>{school?.country?.code}</TableCell>
                <TableCell>{school?.state?.name}</TableCell>
                <TableCell>{school?.state?.code}</TableCell>
                <TableCell>{school?.name}</TableCell>
                <TableCell>{school?.code}</TableCell>
                <TableCell>{school?.typeOfSchool?.name}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      aria-label="edit"
                      onClick={() => {
                        onOpenModalSchool(true);
                        setIdSchool(school?._id);
                        setValue('name', school.name);
                        setValue('code', school.code);
                        setValue('country', school.country._id);
                        setValue('state', school.state._id);
                        setValue('typeOfSchool', school?.typeOfSchool?._id);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => {
                        onOpenModal(true);
                        setIdSchool(school._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalSchool}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={pagesize}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      <Dialog open={open} onClose={() => onOpenModal(false)}>
        <DialogTitle>{t('dialog.title')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => onOpenModal(false)}> {t('dialog.noButton')}</Button>
          <Button
            onClick={() => {
              removeSchool(idSchool);
              onOpenModal(false);
            }}
            autoFocus
          >
            {t('dialog.yesButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
SchoolList.propTypes = {
  schools: PropTypes.array,
  page: PropTypes.number,
  pagesize: PropTypes.number,
  totalSchool: PropTypes.number,
  open: PropTypes.bool,
  onOpenModal: PropTypes.func,
  onOpenModalSchool: PropTypes.func,
  idSchool: PropTypes.string,
  setIdSchool: PropTypes.func,
  removeSchool: PropTypes.func,
  handleOnPageChange: PropTypes.func,
  setValue: PropTypes.func,
  handleLimitChange: PropTypes.func
};
export default SchoolList;
