import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { loginSuccess, loginFailure, resetPasswordRequest } from 'src/redux/actions/auth';
import * as UserSelectors from 'src/redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { FormSchema } from '../models/schema';
import { fields } from '../models/resetpassword';
import { useNotify } from './useNotify';

export const useResetPassword = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(UserSelectors.getAuthUser);
  const error = useSelector(UserSelectors.getAuthError);
  const navigate = useNavigate();
  const { success, error: errorMsg } = useNotify();
  const { schema } = useMemo(
    () => new FormSchema(fields.id, [fields.email, fields.password, fields.passwordConfirmation, fields.token]),
    []
  );

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      [fields.email.name]: props.email,
      [fields.password.name]: '',
      [fields.passwordConfirmation.name]: '',
      [fields.token.name]: props.token
    },
    resolver: schema
  });

  const { isSubmitting } = formState;

  const onResetPassword = useCallback(
    async (formData) => {
      try {
        console.log(formData);
        const { data } = await dispatch(resetPasswordRequest(formData));
        console.log(data);
        success(t('auth.login.forgotSuccess'), { autoHideDuration: 5000 });
        dispatch(loginSuccess(data.data));
        navigate('/login', { replace: true });
      } catch (e) {
        errorMsg(t('auth.login.invalidToken'));
        dispatch(loginFailure(e.message));
      }
    },
    [dispatch, navigate]
  );

  return {
    user,
    error,
    handleSubmit,
    control,
    formState,
    isSubmitting,
    onResetPassword
  };
};
