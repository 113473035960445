import { useTranslation } from 'react-i18next';
// import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import VideoList from 'src/components/videos/list';
import { useVideos } from 'src/hooks/videos/useVideos';
import { Card } from '@mui/material';
import { ModalVideo } from 'src/components/videos/model';
import PlusIcon from '../../icons/Plus';

export const VideosContainer = () => {
  const {
    videos,
    params,
    removeVideos,
    open,
    onOpenModal,
    idVideo,
    setIdVideo,
    onSubmit,
    handleSubmit,
    formItems,
    openModalVideo,
    onOpenModalVideo,
    setValue,
    register,
    watch
  } = useVideos();

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Grid item xs={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            startIcon={<PlusIcon fontSize="small" />}
            onClick={() => {
              onOpenModalVideo(true);
              setIdVideo(null);
            }}
          >
            {t('videos.action.addVideo')}
          </Button>
        </Grid>
      </Grid>
      <Card>
        <VideoList
          videos={videos}
          setValue={setValue}
          removeVideos={removeVideos}
          idVideo={idVideo}
          setIdVideo={setIdVideo}
          open={open}
          onOpenModal={onOpenModal}
          openModalVideo={openModalVideo}
          onOpenModalVideo={onOpenModalVideo}
          {...params}
        />
      </Card>
      <ModalVideo
        watch={watch}
        register={register}
        onSubmit={onSubmit}
        idVideo={idVideo}
        handleSubmit={handleSubmit}
        {...formItems}
        openModalVideo={openModalVideo}
        onOpenModalVideo={onOpenModalVideo}
      />
    </Box>
  );
};
