import axios from 'src/utils/axios';
import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useNotify } from '../useNotify';
import { FormSchema } from '../../models/schema';
import { fields } from '../../models/user';

const initialParams = {
  pagesize: 10,
  page: 0
};

export const useUsersAdmin = (id) => {
  const [idUser, setIdUser] = useState('');
  const [params, setParams] = useState(initialParams);
  const [userName, setUserName] = useState('');
  const [open, setOpen] = useState(false);
  const [openModalUserAdmin, setOpenModalUserAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user1, setUser] = useState({});
  const [error, setError] = useState();

  const { t } = useTranslation();
  const { success, error: errorMsg } = useNotify();

  const { schema } = useMemo(
    () =>
      new FormSchema(fields.id, [
        fields.username,
        fields.password,
        fields.resetPassword,
        fields.fullName,
        fields.gender,
        fields.birthday,
        fields.remarks,
        fields.note,
        fields.phone
      ]),
    []
  );

  const { handleSubmit, control, formState, reset, setValue } = useForm({
    defaultValues: {
      [fields.username.name]: userName || '',
      [fields.resetPassword.name]: '',
      [fields.password.name]: '',
      [fields.fullName.name]: '',
      [fields.gender.name]: '',
      [fields.birthday.name]: moment.utc(new Date()),
      [fields.remarks.name]: '',
      [fields.note.name]: '',
      [fields.phone.name]: 0
    },
    resolver: schema
  });

  const isSubmitting = useMemo(() => formState.isSubmitting, [formState]);

  const formItems = useMemo(
    () => ({
      control,
      formState,
      fullWidth: true
    }),
    [control, formState]
  );

  const onSubmitAdmin = useCallback(async (formData) => {
    console.log('call user admin submit');

    try {
      const formatData = {
        username: userName,
        fullName: formData.fullName,
        gender: formData.gender,
        joinDate: formData.joinDate,
        birthday: formData.birthday,
        password: formData.resetPassword !== '' ? formData.resetPassword : formData.password,
        remarks: formData.remarks,
        note: formData.note,
        phone: formData.phone
      };

      if (idUser) {
        await axios.patch(`admin/user/${idUser}`, formatData);
        console.log('call user admin submit', formatData);

        success(t('users.action.updateSuccess'));
      }
      //  else {
      //   await axios.post('admin/user/register', formatData);
      //   success(t('users.action.createSuccess'));
      // }
      reset();
      setOpenModalUserAdmin(false);
    } catch (e) {
      console.log(e);
      if (idUser) {
        errorMsg(t('users.action.updateFailed'));
      } else {
        errorMsg(t('users.action.createFailed'));
      }
    }
  });

  const onOpenModal = useCallback((isOpen) => {
    setOpen(isOpen);
  }, []);

  const onOpenModalUserAdmin = useCallback((isOpen) => {
    setOpenModalUserAdmin(isOpen);
    reset();
  }, []);

  const fetchUserAdmin = async () => {
    try {
      const { data } = await axios.get(`/admin/user/${id}`);
      if (data) {
        setLoading(false);
        setUser(data?.data);
        setError({ success: data?.isSuccess, error: data?.msg });
      }
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };
  useEffect(() => {
    fetchUserAdmin();
  }, []);

  return {
    setParams,
    params,
    open,
    onOpenModal,
    onOpenModalUserAdmin,
    openModalUserAdmin,
    setValue,
    setIdUser,
    idUser,
    handleSubmit,
    isSubmitting,
    formItems,
    control,
    onSubmitAdmin,
    setUserName,
    fetchUserAdmin,
    user1,
    loading,
    error
  };
};
