import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { logoutRequest, loginSuccess, loginFailure, loginRequest } from 'src/redux/actions/auth';
import * as UserSelectors from 'src/redux/selectors/user';
import { useTranslation } from 'react-i18next';
import { FormSchema } from '../models/schema';
import { fields } from '../models/login';
import { useNotify } from './useNotify';

export const useAuth = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(UserSelectors.getAuthUser);
  const error = useSelector(UserSelectors.getAuthError);
  const navigate = useNavigate();
  const { success, error: errorMsg } = useNotify();
  const { schema } = useMemo(() => new FormSchema(fields.id, [fields.username, fields.password]), []);

  const { handleSubmit, control, formState } = useForm({
    defaultValues: {
      [fields.username.name]: '',
      [fields.password.name]: ''
    },
    resolver: schema
  });

  const { isSubmitting } = formState;

  const onLogin = useCallback(
    async (formData) => {
      try {
        const { data } = await dispatch(loginRequest(formData));
        localStorage.setItem('access_token', data.token);
        success(t('auth.login.success'), { autoHideDuration: 2000 });
        dispatch(loginSuccess(data.data));
        navigate('/admin/user', { replace: true });
      } catch (e) {
        errorMsg(t('auth.login.error'));
        dispatch(loginFailure(e.message));
      }
    },
    [dispatch, navigate]
  );

  const onLogout = useCallback(() => {
    dispatch(logoutRequest());
    localStorage.removeItem('access_token');
  }, [dispatch]);

  return {
    user,
    error,
    onLogin,
    handleSubmit,
    control,
    formState,
    isSubmitting,
    onLogout
  };
};

export const requireAuth = (NewComponent) => (props) => {
  const navigate = useNavigate();
  const user = useSelector(UserSelectors.getAuthUser);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  return !!user && <NewComponent {...props} />;
};
