import * as yup from 'yup';
import _get from 'lodash/get';
import _set from 'lodash/set';
import { yupResolver } from '@hookform/resolvers/yup';

export default class FormSchema {
  constructor(idField, fields, t = () => {}) {
    this.t = t;
    this.idField = idField;
    this.fields = fields;
    this.schema = this.yupResolver();
  }

  initialValues = (record = {}, relation = ['network']) => {
    const initialValues = {
      name: ''
    };
    if (this.idField) {
      _set(initialValues, this.idField.name, this.idField.forFormInitialValue(_get(record, this.idField.name)));
    }

    this.fields.forEach((field) => {
      _set(initialValues, field.name, field.forFormInitialValue(_get(record, field.name)));
    });

    relation.forEach((field) => {
      _set(initialValues, `${field}_id`, _get(record, `${field}`));
    });

    return initialValues;
  };

  yupResolver = () => yupResolver(this.buildSchema());

  buildSchema = () => {
    const shape = {};
    this.fields.forEach((field) => {
      shape[field.name] = field.forForm();
    });

    return yup.object().shape(shape);
  };

  cast = (values) => this.buildSchema().cast(values);

  addField = (field) => {
    if (!this.schema._nodes.includes(field.name)) {
      this.fields.push(field);
      this.schema = this.yupResolver();
    }
  };

  removeField(field) {
    this.fields = this.fields.filter((item) => item.name !== field);
    this.schema = this.yupResolver();
  }
}
