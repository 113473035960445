import { Card, Table, TableBody, TableCell, TableHead, TablePagination, TableRow, Link } from '@mui/material';
import { fields } from 'src/models/user';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useState } from 'react';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
}

const UserList = ({
  users,
  page,
  pagesize,
  handleOnPageChange,
  handleLimitChange,
  totalUsers,
  removeUsers,
  open,
  onOpenModal,
  idUser,
  setIdUser,
  onOpenModalUser,
  setValue
}) => {
  const { t } = useTranslation();
  const [oderDirection, setOrderDirection] = useState('asc');
  const [valueToOderBy, setValueToOderBy] = useState(fields.studentId.label);

  const handleRequestSort = (event, property) => {
    const isAscending = valueToOderBy === property && oderDirection === 'asc';
    setValueToOderBy(property);
    setOrderDirection(isAscending ? 'desc' : 'asc');
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <Card>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{fields.clientId.label}</TableCell>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === fields.username.name}
                direction={valueToOderBy === fields.username.name ? oderDirection : 'asc'}
                onClick={createSortHandler(fields.username.name)}
              >
                {fields.studentId.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={valueToOderBy === fields.fullName.name}
                direction={valueToOderBy === fields.fullName.name ? oderDirection : 'asc'}
                onClick={createSortHandler(fields.fullName.name)}
              >
                {fields.fullName.label}
              </TableSortLabel>
            </TableCell>
            <TableCell>{fields.trainingId.label}</TableCell>
            <TableCell>{fields.gender.label}</TableCell>
            <TableCell>{fields.school.label}</TableCell>
            <TableCell>{fields.joinDate.label}</TableCell>
            <TableCell>{fields.state.label}</TableCell>
            <TableCell>{fields.country.label}</TableCell>
            <TableCell>{fields.status.label}</TableCell>
            <TableCell>{fields.action.label}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            stableSort(users, getComparator(oderDirection, valueToOderBy)).map((user, index) => (
              <TableRow key={user._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <Link component={RouterLink} to={`/admin/user/${user._id}`} underline="none" variant="subtitle2">
                    {user.username}
                  </Link>
                </TableCell>
                <TableCell>{user.fullName}</TableCell>
                <TableCell>{user.trainingId}</TableCell>
                <TableCell>{user.gender}</TableCell>
                <TableCell>{user.school?.name}</TableCell>
                <TableCell> {moment(user.joinDate).format('DD/MM/YYYY')}</TableCell>
                <TableCell>{user.state?.name}</TableCell>
                <TableCell>{user.country?.name}</TableCell>
                <TableCell>{t(`users.status.${user.status}`)}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      aria-label="edit"
                      onClick={() => {
                        onOpenModalUser(true);
                        setIdUser(user._id);
                        setValue(fields.username.name, user.username);
                        setValue(fields.password.name, user.password);
                        setValue(fields.fullName.name, user.fullName);
                        setValue(fields.trainingId.name, user.trainingId);
                        setValue(fields.role.name, user.role._id);
                        setValue(fields.gender.name, user.gender);
                        setValue(fields.birthday.name, user.birthday);
                        setValue(fields.joinDate.name, user.joinDate);
                        setValue(fields.country.name, user.country?._id);
                        setValue(fields.state.name, user.state?._id);
                        setValue(fields.school.name, user.school?._id);
                        setValue(fields.trainingReason.name, user.trainingReason);
                        setValue(fields.remarks.name, user.remarks);
                        setValue(fields.note.name, user.note);
                        setValue(fields.status.name, user.status);
                        setValue(fields.holdingNote.name, user.holdingNote);
                        setValue(fields.expireDate.name, user.expireDate);
                        setValue(fields.activationDate.name, user.activationDate || user.joinDate);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      aria-label="delete"
                      onClick={() => {
                        onOpenModal(true);
                        setIdUser(user._id);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={totalUsers}
        onPageChange={handleOnPageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={pagesize}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
      />
      <Dialog
        open={open}
        onClose={() => onOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('dialog.title')}</DialogTitle>
        <DialogActions>
          <Button onClick={() => onOpenModal(false)}> {t('dialog.noButton')}</Button>
          <Button
            onClick={() => {
              removeUsers(idUser);
              onOpenModal(false);
            }}
            autoFocus
          >
            {t('dialog.yesButton')}
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
UserList.propTypes = {
  users: PropTypes.array,
  page: PropTypes.number,
  pagesize: PropTypes.number,
  totalUsers: PropTypes.number,
  open: PropTypes.bool,
  onOpenModal: PropTypes.func,
  onOpenModalUser: PropTypes.func,
  idUser: PropTypes.string,
  setIdUser: PropTypes.func,
  removeUsers: PropTypes.func,
  handleOnPageChange: PropTypes.func,
  setValue: PropTypes.func,
  handleLimitChange: PropTypes.func
};
export default UserList;
