import axios from 'src/utils/axios';
import { useEffect, useState, useCallback } from 'react';
// import { useTranslation } from 'react-i18next';
// import { useNotify } from '../useNotify';

const initialParams = {
  pagesize: 10,
  page: 0,
  studentname: ''
};

export const useTrainingSignals = () => {
  const [trainingSignals, setTrainingSignals] = useState([]);
  const [error, setError] = useState();
  const [params, setParams] = useState(initialParams);
  const [totalTraingingSignals, setTotalTrainingSignals] = useState(0);

  //   const { success, error: errorMsg } = useNotify();

  const fetchTraingingSignals = async () => {
    try {
      let curbeginstartdate = 0;
      let curendstartdate = 0;
      if (!params.enableStartDateFiltering) {
        curbeginstartdate = 0;
        curendstartdate = 0;
      } else {
        // endbegindate.setDate(endbegindate.getDate() + 1);

        const tempenddate = new Date(params.endbegindate);
        tempenddate.setDate(tempenddate.getDate() + 1);
        curbeginstartdate = params.startbegindate.getTime().toFixed(0);
        curendstartdate = tempenddate.getTime().toFixed(0);
      }
      const { data } = await axios.get(
        `/admin/trainingsignals?pagesize=${params.pagesize}&page=${params.page + 1}&studentname=${
          params.studentname
        }&beginstartdate=${curbeginstartdate}&endstartdate=${curendstartdate}`
      );

      setTrainingSignals(data?.data?.programs);
      setError({ success: data?.isSuccess, error: data?.msg });
      setTotalTrainingSignals(data?.data?.totalProgram);
    } catch (e) {
      console.log(e);
      setError(e);
    }
  };

  const handleLimitChange = useCallback((event) => {
    setParams((pre) => ({
      ...pre,
      pagesize: parseInt(event.target.value, 10),
      page: 0
    }));
  }, []);

  const handleOnPageChange = (event, newPage) => {
    setParams((pre) => ({
      ...pre,
      page: newPage
    }));
  };

  //   useEffect(() => {
  //     fetchTraingingSignals();
  //   }, []);

  const handleSearchChange = (event) => {
    setParams((pre) => ({
      ...pre,
      studentname: event.target.value,
      page: 0
    }));
  };

  const handleBeginStartDay = (event) => {
    console.log(event);
    setParams((pre) => ({
      ...pre,
      startbegindate: event,
      page: 0
    }));
  };
  useEffect(() => {
    fetchTraingingSignals();
  }, [params]);

  const handleEndStartDay = (event) => {
    setParams((pre) => ({
      ...pre,
      endbegindate: event,
      page: 0
    }));
  };

  const handleStartDateFiltering = (event) => {
    console.log(event);
    setParams((pre) => ({
      ...pre,
      enableStartDateFiltering: event.target.checked,
      page: 0
    }));
  };

  return {
    trainingSignals,
    setParams,
    params,
    error,
    handleOnPageChange,
    handleLimitChange,
    totalTraingingSignals,
    handleSearchChange,
    handleBeginStartDay,
    handleEndStartDay,
    handleStartDateFiltering,
    fetchTraingingSignals
  };
};
