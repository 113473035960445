import { Grid, Box, Card } from '@mui/material';
import { useStates } from 'src/hooks/user/useStates';
import StateList from 'src/components/states/StateList';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { ModalState } from 'src/components/states/ModalState';
import PlusIcon from '../../icons/Plus';
import { stateFields } from '../../models/state';

export const StatesContainer = () => {
  const {
    states,
    params,
    handleOnPageChange,
    handleLimitChange,
    totalState,
    removeState,
    open,
    onOpenModal,
    idState,
    setIdState,
    onSubmit,
    handleSubmit,
    formItems,
    openModalState,
    onOpenModalState,
    setValue
  } = useStates();

  const { t } = useTranslation();

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%'
      }}
    >
      <Grid container justifyContent="flex-end" style={{ marginBottom: '20px' }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<PlusIcon fontSize="small" />}
          onClick={() => {
            onOpenModalState(true);
            setIdState(null);
          }}
        >
          {t('actions.add', { name: stateFields.state.label })}
        </Button>
      </Grid>
      <Card>
        <StateList
          states={states}
          setValue={setValue}
          totalState={totalState}
          removeState={removeState}
          idState={idState}
          setIdState={setIdState}
          open={open}
          onOpenModal={onOpenModal}
          handleOnPageChange={handleOnPageChange}
          handleLimitChange={handleLimitChange}
          openModalState={openModalState}
          onOpenModalState={onOpenModalState}
          {...params}
        />
      </Card>
      <ModalState
        onSubmit={onSubmit}
        idState={idState}
        handleSubmit={handleSubmit}
        {...formItems}
        openModalState={openModalState}
        onOpenModalState={onOpenModalState}
      />
    </Box>
  );
};
