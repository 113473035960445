import { i18n } from '../i18n';
import StringField from './fields/stringField';
import IdField from './fields/idField';

const label = (field) => i18n.t(`form.fields.${field}`);
const placeholder = (field) => i18n.t(`form.placeholder.${field}`);

export const videoFields = {
  id: new IdField('id', 'id'),
  title: new StringField('title', label('title'), placeholder('title')),
  status: new StringField('status', label('status'), placeholder('status')),
  action: new StringField('action', label('action'), placeholder('action'))
};

export default {
  videoFields,
  placeholder
};
